.footer-container {
  position: relative;
  padding: 32px 0;
  background-color: color(secondary, dark-blue);
  border-top: 2px solid color(primary, gold);
  box-shadow: inset 0 2px 0 0 color(primary, gold);
  color: color(primary, white);

  @include media('>=tablet') {
    padding: 64px 0;
  }

  @include media('>=laptop') {
    padding: 128px 0;
    border-top: 4px solid color(primary, gold);
    box-shadow: inset 0 4px 0 0 color(primary, gold);
  }


  // General
  // ---------------------------------------------------------------------------
  a {

    // default links, not visited
    &:link {
      color: color(primary, white);
    }

    // links that have been visited
    &:visited {
      color: color(primary, white);
    }

    // hover state
    &:hover {
      background-color: lighten(color(secondary, dark-blue), 5%);
      color: color(primary, white);
    }

    // focus state
    &:focus {
      background-color: color(secondary, gold);
      box-shadow: 0 0 0 4px color(secondary, gold);
      color: color(secondary, dark-blue);
    }

    // active state
    &:active {
      background-color: color(secondary, gold);
      box-shadow: 0 0 0 4px color(secondary, gold);
      color: color(secondary, dark-blue);
    }

    // external links
    // -------------------------------------------------------------------------
    &[target='_blank'] {

      &:after {
        background-color: lighten(color(secondary, dark-blue), 5%);
        background-image: url('../img/icons/i_external_white.svg');
      }
    }
  }


  .panel-group {

    // Additional information
    &--additional {
      @include reset-footer-title;

      @include media('>=desktop') {
        width: percentage(630/1280);
        margin-bottom: 0;
      }

      .links__list {
        @include media('>=tablet') {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .links__item {
        @include media('>=tablet') {
          width: percentage(300/624);
        }

        &:nth-child(2) {
          @include media('>=tablet') {
            margin-top: 0;
          }
        }
      }
    }

    // Community support
    &--community {
      @include media('>=desktop') {
        width: percentage(303/1280);
      }

      @include reset-footer-title;
    }

    // Help and support
    &--help-support {
      @include media('>=desktop') {
        width: percentage(303/1280);
      }

      @include reset-footer-title;
    }

    // Site information
    &--legal {
      @include media('>=desktop') {
        width: auto;
      }

      // panel title
      .panel__title {
        @include media('>=desktop') {
          @include hide-off-screen;
        }
      }

      // panel content
      .panel__content {
        @include media('>=desktop') {
          margin-top: 0;
        }
      }

      // links items
      .links__item {
        @include media('>=desktop') {
          display: inline-block;
          margin-top: 0;
          margin-right: 16px;
        }

        &:first-child {
          font-weight: 600;
        }
      }
    }

  }


  // Links
  // ---------------------------------------------------------------------------
  .links {

    // Social items
    &__item--facebook {
      a:before { background-image: url('../img/icons/i_social-facebook_white.svg'); }
    }

    &__item--twitter {
      a:before { background-image: url('../img/icons/i_social-twitter_white.svg'); }
    }

    &__item--linkedin {
      a:before { background-image: url('../img/icons/i_social-linkedin_white.svg'); }
    }

    &__item--youtube {
      a:before { background-image: url('../img/icons/i_social-youtube_white.svg'); }
    }

    &__item--email {
      a:before { background-image: url('../img/icons/i_social-email_white.svg'); }
    }

    // Connect with us
    &--connect {
      margin-top: 32px;
      text-align: center;

      @include media('>=desktop') {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
      }

      @include media('>=desktop_wide') {
        justify-content: flex-end;
      }

      .links__title {
        white-space: nowrap;

        @include media('>=tablet') {
          margin-bottom: 16px;
          font-size: 16px;
        }

        @include media('>=desktop') {
          margin: 0 16px 0 0;
          font-size: 16px;
        }

        &:after {
          content: ':';
        }
      }

      .links__list {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
        line-height: 0;
      }

      .links__item {
        width: 32px;
        height: 32px;
        margin: 0 8px;
      }

      .links__item a {
        width: 100%;
        height: 100%;
        margin: 0;

        // focus state
        &:focus {
          background-color: transparent;
        }
      }
    }

  }


  // Primary footer area
  // ---------------------------------------------------------------------------
  .primary {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    @include media('>=desktop') {
      justify-content: space-between;
      padding-bottom: 64px;
      margin-top: 0;
    }
  }


  // Secondary footer area
  // ---------------------------------------------------------------------------
  .secondary {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    justify-content: center;

    @include media('>=desktop') {
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-top: 64px;
      border-top: 2px solid color(primary, gold);
      font-size: 16px;
    }

    // stars
    &:before {
      @include media('>=desktop') {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 124px;
        height: 24px;
        background: color(secondary, dark-blue) url('../img/graphics/g_stars.svg') no-repeat 50% 50% / 80px auto;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    // logo
    .logo {
      order: 2;

      @include media('>=desktop') {
        order: 1;
      }
    }

    // links
    .links {
      order: 1;

      @include media('>=desktop') {
        order: 2;
      }
    }

    // copyright
    .copyright {
      order: 3;
    }
  }
}
