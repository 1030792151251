// Cards
//
// A card is a sheet of material that serves as an entry point to more detailed information.
//
// Markup:
// <div class="card {{modifier_class}}">
//   <i class="card__icon card__icon--fill" aria-hidden="true">
//     <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" xml:space="preserve" width="48" height="48"><g class="nc-icon-wrapper" fill="#444444"><path fill="#444444" d="M44.74,25.327l-20-22c-0.397-0.437-1.084-0.437-1.48,0l-20,22c-0.266,0.293-0.334,0.715-0.174,1.078 C3.246,26.767,3.604,27,4,27h5v18c0,1.105,0.895,2,2,2h8c0.552,0,1-0.448,1-1V36h8v10c0,0.552,0.448,1,1,1h8c1.105,0,2-0.895,2-2V27 h5c0.396,0,0.754-0.233,0.914-0.595C45.074,26.042,45.006,25.62,44.74,25.327z"></path></g></svg>
//   </i>
//   <h2 class="card__title">Card title</h2>
//   <p class="card__description">Every card should have a description to briefly explain its content.</p>
//   <a class="card__action" href="#">Action</a>
// </div>
//
// .card--inverted    - Inverted card
// .card--basic       - Basic card
// .card--horizontal  - Horizontal card
//
// Styleguide Components - Cards

.card {
  position: relative;
  padding: 32px;
  background-color: color(primary, white);
  border-radius: radius(base);
  box-shadow: 0 2px 4px rgba(color(primary, black), .16);
  overflow: hidden;
  transition:
    background $base-animation-speed $base-animation-easing,
    box-shadow $base-animation-speed $base-animation-easing;

  @include media('>=laptop') {
    padding: 64px;
  }


  // Hover state
  &:hover {
    box-shadow: 0 8px 16px rgba(color(primary, black), .16);
  }


  // Adjacent cards
  + .card {
    margin-top: 16px;

    @include media('>=tablet') {
      margin-top: 24px;
    }
    @include media('>=laptop') {
      margin-top: 32px;
    }
  }


  // Card icon
  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    margin-bottom: 32px;

    @include media('>=laptop') {
      width: 48px;
      height: 48px;
    }

    svg {
      width: 100%;
      height: auto;
    }

    &--fill { svg * { fill: color(secondary, dark-blue); } }
    &--stroke { svg * { stroke: color(secondary, dark-blue); } }
  }


  // Card title
  &__title {
    margin: 0 0 16px;
    font: 600 16px / 1.25 $font-secondary;

    @include media('>=tablet') {
      font-size: 19px;
    }

    @include media('>=laptop') {
      font-size: 21px;
    }
    .band[data-columns='2'] & {
      @include media('>=laptop') {
        font-size: 32px;
      }
    }
  }


  // Card description
  &__description {
    margin-bottom: 32px;
    color: color(primary, gray);
  }


  // Card action
  &__action {
    @extend .button;
  }


  // Inverted card
  // ---------------------------------------------------------------------------
  &--inverted {
    background-color: color(secondary, dark-blue);
    color: color(primary, white);

    // card icon
    .card__icon {
      &--fill { svg * { fill: color(primary, white); } }
      &--stroke { svg * { stroke: color(primary, white); } }
    }

    // card description
    .card__description {
      color: rgba(color(primary, white), .75);
    }

    // card action
    .card__action {
      @extend .button--secondary;
    }

    // buttons
    .button {
      @extend .button--secondary;
    }

    // buttons
    [type='submit'] {
      @extend .button--tertiary;
    }
  }


  // Basic card
  // ---------------------------------------------------------------------------
  &--basic {
    height: auto;
    padding: 32px;
    background-color: color(primary, off-white);
    box-shadow: none;

    // disable hover
    &:hover {
      box-shadow: none;
    }

    .card-title {
      .band[data-columns='2'] & {
        @include media('>=laptop') {
          font-size: 21px;
        }
      }
    }

    // hide unsupported elements
    &:before,
    .card__icon { display: none; }

    // card description
    .card__description {
      color: color(primary, black);
    }

    // remove unwanted margin on last element
    > *:last-child { margin-bottom: 0; }

  }


  // Horizontal card
  // ---------------------------------------------------------------------------
  &--horizontal {
    @include media('>=laptop') {
      padding-left: 144px;
    }

    .card__icon {
      @include media('>=laptop') {
        position: absolute;
        top: 64px;
        left: 64px;
      }
    }

    .card__title {
      @include media('>=laptop') {
        font-size: 24px;
      }
      .band[data-columns='2'] & {
        @include media('>=laptop') {
          font-size: 24px;
        }
      }
    }
  }

  &:not(.card--horizontal) {
    display: flex;
    flex-direction: column;
    > * {
      max-width: 100%;
    }
  }
}

.band--striped {
  .card:not(.card--horizontal) {
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
  }
}
