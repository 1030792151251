.header-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: 68px;
  background-color: color(primary, white);
  box-shadow: 0 1px 0 0 rgba(color(primary, gray), .25);
  transition: box-shadow $base-animation-speed * 2 $base-animation-easing;
  z-index: 10000;

  @include media('>=tablet') {
    height: 84px;
  }
  @include media('>=laptop') {
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 80px;
    border: 0;
    font-size: 16px;
  }

  // overlays
  &:before {
    @extend %overlay;
  }

  // header margins
  + * {
    margin-top: 68px;

    @include media('>=tablet') {
      margin-top: 84px;
    }
    @include media('>=laptop') {
      margin-top: 160px;
    }
    @include media('>=1366px') {
      margin-top: 80px;
    }
  }

  // header actions
  .actions {
    display: flex;
    // IE 11 fix
    flex: 0 0 auto;
    // end IE 11 fix
    justify-content: space-between;
    width: 100%;

    @include media('>=laptop') {
      width: auto;
    }
  }

  // header panels
  .panel {
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: color(primary, white);
    overflow-y: auto;
    transition: visibility $base-animation-speed * 2 $base-animation-easing,
    transform $base-animation-speed * 2 $base-animation-easing;
    z-index: 10002;

    @include media('>=tablet') {
      box-shadow: -2px 0 8px 0 rgba(color(primary, gray), .25);
    }

    @include media('>=laptop') {
      position: relative;
      top: auto;
      left: auto;
      padding: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      opacity: 1;
      overflow: visible;
      transition: none;
      transform: none;
      z-index: auto;
    }

    // Menu panel
    // -------------------------------------------------------------------------
    &--menu {
      left: 100%;
      padding: 64px 32px 32px;
      opacity: 1;
      visibility: hidden;
      z-index: 10002;

      @include media('>=laptop') {
        width: auto;
        left: auto;
        padding: 0;
        transform: none;
        visibility: visible;
      }

      // active state
      &.panel--active {
        transform: translateX(-100%);
        visibility: visible;
      }
    }

    // Search panel
    // -------------------------------------------------------------------------
    &--search {
      left: 0;
      background-color: color(primary, off-white);
      transform: translateX(-100%);

      @include media('>=laptop') {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex: 2 0 auto;
        width: auto;
        background-color: color(primary, white);
        border-top: 1px solid rgba(color(primary, gray), .15);
        box-shadow: 0 2px 8px 0 rgba(color(primary, gray), .25);
        transform: none;
      }
      @include media('>=1366px') {
        position: relative;
        top: auto;
        left: auto;
        margin-left: 16px;
        background-color: transparent;
        border: 0;
        box-shadow: none;
      }

      @include media('<1366px', '>=laptop') {
        width: 100%;
      }

      // active state
      &.panel--active {
        transform: translateX(0%);
      }
    }

    // Suggestions panel
    // -------------------------------------------------------------------------
    &--suggestions {
      position: static;
      padding: 100px 32px 32px;
      background-color: transparent;
      z-index: 100;

      @include media('>=tablet') {
        padding: 148px 72px 64px;
      }
      @include media('>=laptop') {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 480px;
        height: auto;
        max-height: 75vh;
        padding: 64px;
        margin-top: 4px;
        background-color: color(primary, white);
        border-radius: radius(base);
        box-shadow: 0 2px 8px 0 rgba(color(primary, gray), .25);
        opacity: 0;
        transform: translateY(-16px);
        transition-property: opacity, transform, visibility;
        transition-duration: 70ms, 250ms, 250ms;
        transition-timing-function: linear, cubic-bezier(.23, 1, .32, 1);
        transition-delay: 25ms, 50ms, 0ms;
        visibility: hidden;
        overflow-y: scroll;
      }

      &.panel--active {
        @include media('>=laptop') {
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          transition-duration: 100ms, 150ms, 25ms;
          transition-delay: 35ms, 50ms, 25ms;
        }
      }
    }
  }
}
