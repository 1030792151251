// Zones
//
// Multi-column compositions for your content.
//
// Styleguide Layouts - Zones

.zones {
  margin-bottom: 48px;

  @include media('>=tablet') {
    margin-bottom: 96px;
  }
  @include media('>=desktop') {
    display: flex;
    justify-content: space-between;
    margin-bottom: 128px;
  }

  > .wrapper {
    @include media('>=desktop') {
      display: flex;
      justify-content: space-between;
    }
  }


  // 2 Zones
  //
  // Markup:
  // <div class="zones" data-columns="2">
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  // </div>
  //
  // Styleguide Layouts - Zones - 2 Zones

  &[data-columns='2'] {
    .zone {
      @include media('>=desktop') {
        width: percentage(303/1280);
      }
    }
    .zone:first-child {
      @include media('>=desktop') {
        width: percentage(846/1280);
      }
    }
  }


  // 2 Zones (Reverse)
  //
  // Markup:
  // <div class="zones" data-columns="2" data-layout-reverse>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  // </div>
  //
  // Styleguide Layouts - Zones - 2 Zones (Reverse)

  &[data-columns='2'][data-layout-reverse] {
    .zone {
      @include media('>=desktop') {
        width: percentage(846/1280);
      }
    }
    .zone:first-child {
      @include media('>=desktop') {
        width: percentage(303/1280);
      }
    }
  }


  // 3 Zones
  //
  // Markup:
  // <div class="zones" data-columns="3">
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  // </div>
  //
  // Styleguide Layouts - Zones - 3 Zones

  &[data-columns='3'] {
    .zone {
      @include media('>=desktop') {
        width: percentage(200/1280);
        order: 1;
      }
    }
    .zone:first-child {
      @include media('>=desktop') {
        width: percentage(630/1280);
        order: 2;
      }

      // adjacent zone
      + .zone {
        padding-top: 32px;
        border-top: 2px solid color(primary, black);

        @include media('>=tablet') {
          padding-top: 48px;
        }


        @include media('>=desktop') {
          padding-top: 0;
          border-top: 0;
        }
      }

      // bands
      .band {
        @include media('>=laptop') {
          padding: 64px 0 0;
        }

        // First band
        &:first-child {
          padding: 0;
        }

        // Only child
        &:only-child {
          @include media('>=laptop') {
            padding: 0 0 64px;
          }
        }
      }
    }
    .zone:last-child {
      @include media('>=desktop') {
        order: 3;
      }
    }
  }


  // 3 Zones (Compact)
  //
  // Markup:
  // <div class="zones" data-columns="3" data-layout-compact>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  //   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
  //   </div>
  // </div>
  //
  // Styleguide Layouts - Zones - 3 Zones (Compact)

  &[data-columns='3'][data-layout-compact] {
    .zone {
      @include media('>=desktop') {
        width: percentage(255/1280);
        order: 1;
      }
    }
    .zone:first-child {
      @include media('>=desktop') {
        width: percentage(630/1280);
        order: 2;
      }
    }
    .zone:last-child {
      @include media('>=desktop') {
        order: 3;
      }
    }
  }

  &[data-columns='3'][data-layout-people] {
    > .wrapper {
      display: flex;
      flex-direction: column;
      @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .zone:first-child + .zone {
      order: 3;
      width: 100%;
      @include media('>=desktop') {
        width: auto;
        order: 1
      }
    }
    .zone:first-child {
      order: 2;
      margin-top: 32px;
      @include media('>=desktop') {
        margin-top: 0;
      }
    }
    .zone:last-child {
      order: 1;
      @include media('>=desktop') {
        order: 3
      }
    }
  }
}

// 1 Zone
//
// Markup:
// <div class="zones">
//   <div class="zone" style="padding: 32px; background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
//     All business pertaining to court, cases, records, filings, fees, traffic violations, jury duty, etc., should be referred to the county courthouse in which the matter is filed or occurred.
//   </div>
// </div>
//
// Styleguide Layouts - Zones - 1 Zone

.zone {
  width: 100%;
  margin-top: 32px;

  @include media('>=tablet') {
    margin-top: 48px;
  }

  @include media('>=laptop') {
    margin-top: 64px;
  }

  @include media('>=desktop') {
    margin-top: 0;
  }

  // optional zone title for screen readers
  &__title {
    @extend %hide-off-screen;
  }

  // first zone
  &:first-child {
    margin-top: 0;
  }

}
