.districts {
  @include reset-list;
  display: none;

  @include media('>=tablet') {
    display: flex;
  }
}

.district {
  @include media('>=tablet') {
    width: percentage(1/3);
    padding: 32px 0;
    margin: 0;
    border-left: 1px solid lighten(color(primary, gray), 35%);
    text-align: center;
  }

  &:first-child {
    @include media('>=tablet') {
      border-left: 0;
    }
  }

  &__meta {
    @extend .meta;

    @include media('>=tablet') {
      display: block;
    }
  }

  &__value {}

}
