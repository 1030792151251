// Files
//
// Digital document that users can download.
//
// Markup:
// <section class="list list--files">
//   <div class="list__items">
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.doc" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.xls" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.ppt" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.pdf" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.jpg" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//     <article class="list__item file" lang itemscope itemtype="http://schema.org/DigitalDocument">
//       <h1 class="list__title" itemprop="headline"><a itemprop="url" href="sample-file.txt" target="_blank"><span>Request title for abandoned property</span></a></h1>
//       <div class="meta">
//         <span itemprop="fileFormat">Word document</span>, 41KB
//       </div>
//     </article>
//   </div>
// </section>
//
// Styleguide Components - Files

.file {
  position: relative;


  [itemprop='url'] {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -56px;
      display: block;
      width: 40px;
      height: 48px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: auto 100%;

      @include media('>=tablet') {
        left: -85px;
        width: 53px;
        height: 64px;
      }
    }

    // documents
    &[href*='.doc']:before,
    &[href*='.docx']:before { background-image: url('../img/graphics/g_file-doc.svg'); }

    // spreadsheets
    &[href*='.xls']:before,
    &[href*='.xlsx']:before,
    &[href*='.csv']:before { background-image: url('../img/graphics/g_file-xls.svg'); }

    // presentations
    &[href*='.ppt']:before { background-image: url('../img/graphics/g_file-ppt.svg'); }

    // pdfs
    &[href*='.pdf']:before,
    // some pdf links don't have extensions.
    &.pdf_link:before { background-image: url('../img/graphics/g_file-pdf.svg'); }

    // images
    &[href*='.jpg']:before,
    &[href*='.png']:before,
    &[href*='.gif']:before { background-image: url('../img/graphics/g_file-img.svg'); }

    // texts
    &[href*='.txt']:before { background-image: url('../img/graphics/g_file-txt.svg'); }

    // archive
    &[href*='.zip']:before,
    // some zip links don't have extensions.
    &.zip_link:before { background-image: url('../img/graphics/g_file-zip.svg'); }

  }

}
