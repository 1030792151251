// Blockquote
//
// The blockquote element is a mechanism for marking up a block of text quoted from a person or another document or source.
//
// Markup:
// <blockquote>
//   <p>Design is not just what it looks like and feels like. Design is how it works.</p>
//   <footer>
//     Steve Jobs
//   </footer>
// </blockquote>
//
// Styleguide Elements - Blockquote

blockquote {
  padding: 0 28px;
  margin: 56px 0;
  border-left: 4px solid color(primary, gold);
  font-family: $font-primary;
  font-size: 28px;
  line-height: 1.25;

  @include media('>=tablet') {
    padding: 0 32px;
    margin: 64px 0;
    font-size: 32px;
  }
  @include media('>=laptop') {
    padding: 0 38px;
    margin: 76px 0;
  }

  p {
    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }

  // citation
  footer {
    font-family: $font-secondary;
    font-style: normal;
    font-size: 16px;

    &:before {
      content: '\2014';
      display: inline-block;
      margin-right: 8px;
    }
  }

}
