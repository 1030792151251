.menu {

  // Default
  // ---------------------------------------------------------------------------

  // Menu title
  &__title {
    @extend .h5;
    margin-top: 0;
  }

  // Menu subtitle
  &__subtitle {
    padding: 8px 0;
    margin: 0;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 14px;
    color: color(primary, gray);

    @include media('>=laptop') {
      padding: 8px 16px;
    }
  }

  @at-root aside .menu__subtitle {
    @include media('>=laptop') {
      padding-left: 0;
      padding-right: 0;
    }

    + .menu__list {
      margin-top: 0;
    }
  }

  // Menu divider
  &__divider {
    @extend hr;
    margin: 8px 0;
  }

  // Menu list
  &__list {
    padding: 0;
    list-style: none;
  }

  // Menu item
  &__item {
    @extend %sidebar__item;

    // dropdown menu items
    &--dropdown {
      position: relative;

      > a {
        &:after {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('../img/icons/i_caret-down.svg') no-repeat 50% 50% / 100% auto;
          transition: transform $base-animation-speed * 2 $base-animation-easing;
        }
      }

      &.menu__item--active > a {
        &:after {
          transform: rotateX(180deg);
        }
      }
    }
  }

  // Menu dropdowns
  // ---------------------------------------------------------------------------
  &__dropdown {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition:
      max-height $base-animation-speed $base-animation-easing,
      opacity $base-animation-speed $base-animation-easing;

    @include media('>=laptop') {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 240px;
      max-height: 1000px;
      padding: 8px 0;
      margin-top: 4px;
      background-color: color(primary, white);
      border: 1px solid rgba(color(primary, gray), .25);
      border-radius: radius(base);
      box-shadow: 0 2px 4px rgba(color(primary, black), .1);
      overflow: auto;
      transform: translateY(-16px);
      transition:
        opacity $base-animation-speed $base-animation-easing,
        visibility $base-animation-speed $base-animation-easing,
        transform $base-animation-speed $base-animation-easing;
      visibility: hidden;
      backface-visibility: hidden;
    }

    // Defaults
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      margin: 0;
    }

    a {
      padding: 8px;
    }

    // Active state
    &--active {
      max-height: 1000px;
      margin: 16px 0 32px;
      opacity: 1;

      @include media('>=laptop') {
        margin: 4px 0 0;
        transform: translateY(0);
        visibility: visible;
      }
    }
  }


  // Primary menu
  // ---------------------------------------------------------------------------
  &--primary {
    font-size: 16px;

    @include media('>=laptop') {
      height: 100%;
      font-size: 14px;
    }
    @include media('>=desktop') {
      font-size: 16px;
    }

    // Menu title
    .menu__title {
      padding: 64px 64px 16px 32px;
      margin: -64px -32px 16px;
      background-color: color(secondary, dark-blue);
      color: color(primary, white);
      font-size: 32px;

      @include media('>=laptop') {
        @include hide-off-screen;
      }
    }

    // Menu list
    .menu__list {
      margin: 0;

      @include media('>=laptop') {
        display: flex;
        align-content: center;
        justify-content: flex-end;
        height: 100%;
      }
    }

    // Menu item
    .menu__item {
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);

      @include media('>=laptop') {
        display: flex;
        border: 0;
        white-space: nowrap;
      }

      a {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: space-between;
        padding: 16px 0;
        background: none;
        color: color(primary, black);
        font-weight: 600;
        text-decoration: none;
        transition:
          color $base-animation-speed $base-animation-easing,
          box-shadow $base-animation-speed $base-animation-easing;

        @include media('>=laptop') {
          height: 100%;
          padding: 0 16px;
          font-weight: 400;
        }

        // hover state
        &:hover {
          color: color(primary, blue);

          @include media('>=laptop') {
            box-shadow: 0 1px 0 0 rgba(color(primary, gray), .5);
          }
        }

        // focus state
        &:focus,
        &:focus:hover {
          box-shadow: inset 0 0 0 4px color(secondary, gold);
          outline: 0;
        }

      }

      // first item
      &:first-child {
        border: 0;

        @include media('>=laptop') {
          margin-left: 16px;
        }
      }

      // last item
      &--last-child {
        margin-top: 32px;
        border: 0;
        border-radius: radius(base);
        overflow: hidden;

        @include media('>=laptop') {
          margin-top: 0;
          margin-left: 16px;
          border-radius: 0;
          overflow: auto;
        }

        a {
          display: flex;
          align-items: center;
          align-self: inherit;
          height: auto;
          padding: 24px 32px;
          background-color: color(secondary, cyan);
          color: color(primary, white);
          transition: background $base-animation-speed $base-animation-easing;

          @include media('>=laptop') {
            padding: 0 32px;
          }

          // icon addon
          &:after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 16px;
            background: url('../img/icons/i_arrow-right_white.svg') no-repeat 50% 50% / 100% auto;
            transition: transform $base-animation-speed * 2 $base-animation-easing;
          }

          // hover state
          &:hover {
            background-color: lighten(color(secondary, cyan), 2%);
            box-shadow: none;
            color: color(primary, white);
          }

          &:hover:after {
            transform: translateX(2px);
          }

          // focus state
          &:focus {
            box-shadow: inset 0 0 0 4px color(secondary, gold);
          }
        }
      }

      // active state
      // -----------------------------------------------------------------------
      &--active {
        > a,
        > a:hover {
          @include media('>=laptop') {
            box-shadow: 0 1px color(secondary, dark-blue);
          }
        }
      }

      // current state
      // -----------------------------------------------------------------------
      &--current {
        // default and hover states
        > a,
        > a:hover {
          @include media('>=laptop') {
            box-shadow: 0 1px color(secondary, dark-blue);
          }
        }

        // focus state
        > a:focus {
          @include media('>=laptop') {
            box-shadow: inset 0 0 0 4px color(secondary, gold);
          }
        }
      }
    }

    .menu__dropdown a {
      padding: 8px 0;
      text-decoration: underline;

      @include media('>=laptop') {
        padding: 8px 16px;
        text-decoration: none;
      }

      // hover state
      &:hover {
        background-color: rgba(color(primary, gray), .05);
        box-shadow: none;
      }
    }
  }


  // Secondary menu
  // ---------------------------------------------------------------------------
  &--secondary {
    @extend .sideblock;

    // Menu title
    .menu__title {
      @include media('<=tablet') {
        padding: 64px 64px 16px 32px;
      }
      @include media('<laptop') {
        padding: 64px 64px 16px 64px;
        margin: 64px -64px 16px;
        background-color: color(secondary, dark-blue);
        color: color(primary, white);
        font-size: 24px;
        font-weight: 700;
      }
      @include media('>=laptop') {
        font: 600 19px / 1.25 $font-secondary;
        margin: 0 0 19px;
      }
    }

    // Menu list
    .menu__list {
      @extend .sideblock__content;
    }

    // Menu item
    .menu__item {
      @include media('<laptop') {
        margin: 0;
        border-top: 1px solid lighten(color(primary, gray), 35%);
      }

      a {
        @include media('<laptop') {
          display: flex;
          align-items: center;
          align-self: center;
          justify-content: space-between;
          padding: 16px 0;
          background: none;
          font-size: 16px;
          font-weight: 600;
          transition:
            color $base-animation-speed $base-animation-easing,
            box-shadow $base-animation-speed $base-animation-easing;
        }
      }

      // first item
      &:first-child {
        @include media('<laptop') {
          border: 0;
        }
      }
    }

    // Active menu item
    .menu__item--active {
      > a {
        @include media('>=laptop') {
          position: relative;
          background: none;
          font-weight: 600;
        }

        &:before {
          @include media('>=laptop') {
            content: '';
            position: absolute;
            top: 9px;
            left: -24px;
            width: 8px;
            height: 8px;
            background-color: color(primary, gold);
            border-radius: radius(full);
          }
        }
      }
    }

  }


  // Numbered menu
  // ---------------------------------------------------------------------------
  &--numbered {

    // Menu list
    .menu__item {
      position: relative;
      counter-increment: step-counter;

      &:before {
        content: counter(step-counter) '.';
        position: absolute;
        top: 0;
        right: 100%;
        width: 24px;
        margin-right: 8px;
        color: color(primary, gray);
        text-align: right;

        @include media('>=tablet') {
          margin-right: 16px;
        }
      }
    }

  }

}
