// Inline Definitions
//
// Display a list where labels are inline with values using the <code>inline-definitions</code> class.
//
// Markup:
// <dl class="inline-definitions">
//   <dt>Friday</dt>
//   <dd>9:00am - 5:00pm</dd>
//   <dt>Saturday</dt>
//   <dd>Closed</dd>
//   <dt>Sunday</dt>
//   <dd>Closed</dd>
//   <dt>Monday</dt>
//   <dd>9:00am - 5:00pm</dd>
//   <dt>Tuesday</dt>
//   <dd>9:00am - 5:00pm</dd>
//   <dt>Wednesday</dt>
//   <dd>9:00am - 5:00pm</dd>
//   <dt>Thursday</dt>
//   <dd>9:00am - 5:00pm</dd>
// </dl>
//
// Styleguide Components - Inline Definitions

.inline-definitions {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 44px;

  @include media('>=tablet') {
    margin-bottom: 48px;
  }

  @include media('>=laptop') {
    margin-bottom: 54px;
  }

  // default items
  dt, dd {
    width: 50%;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid rgba(color(primary, black), .1);
  }

  dt { padding-right: 12px; }
  dd { padding-left: 12px; }

  // first row
  dt:first-of-type,
  dd:first-of-type {
    margin-top: 0;
    border-top: 0;
  }

}
