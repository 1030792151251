// Paragraph
//
// The p element is one of the most commonly used building blocks of HTML.
//
// Markup:
// <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam egestas odio tortor, sed vehicula nunc lobortis rutrum. Cras ultrices luctus purus non malesuada. Morbi accumsan, justo ut venenatis aliquet, risus elit sollicitudin tellus, eu vestibulum velit odio a justo.</p>
//
// Styleguide Elements - Paragraph

p {
  margin: 0 0 28px;

  @include media('>=tablet') {
    margin-bottom: 32px;
  }

  @include media('>=laptop') {
    margin-bottom: 38px;
  }
}


// Strong
//
// The HTML <code>strong</code> element gives text strong importance, and is typically displayed in bold.
//
// Markup:
// <p>When doing x it is <strong>imperative</strong> to do y before proceeding.</p>
//
// Styleguide Elements - Strong

strong { font-weight: 600; }


// Casing
//
// Styleguide Elements - Casing

// All caps
//
// Convert any string of text into all-caps gracefully without impacting accessibility.
//
// Markup:
// <span class="casing--caps">Some text label</span>
//
// Styleguide Elements - Casing - All caps

.casing--caps { text-transform: uppercase; }


// Lowercase
//
// Convert any string of text into lowercase gracefully without impacting accessibility.
//
// Markup:
// <span class="casing--lowercase">Some text label</span>
//
// Styleguide Elements - Casing - Lowercase

.casing--lowercase { text-transform: lowercase; }


// Mark
//
// The HTML <code>mark</code> element represents highlighted text, i.e., a run of text marked for reference purpose, due to its relevance in a particular context.
//
// Markup:
// <p>When doing x it is <mark>imperative</mark> to do y before proceeding.</p>
//
// Styleguide Elements - Mark

mark {
  background-color: color(secondary, gold);
  box-shadow: 0 0 0 4px color(secondary, gold);
}


// Abbreviation
//
// The HTML <code>abbr</code> element represents an abbreviation and optionally provides a full description for it. If present, the title attribute must contain this full description and nothing else.
//
// Markup:
// <abbr title="Laugh Out Loud">LOL</abbr>
//
// Styleguide Elements - Abbreviation

abbr[title] {
  border-bottom: 1px dotted;
  font-variant: none;
  text-decoration: none;
  cursor: help;
}
