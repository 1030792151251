// Blockquote (Featured)
//
// Promotional blockquote that displays an associated image.
//
// Markup:
// <blockquote class="blockquote--featured">
//   <div class="blockquote__image">
//     <img src="https://unsplash.it/320/320" alt="caption for image">
//   </div>
//   <div class="blockquote__content">
//     <p class="blockquote__text">Design is not just what it looks like and feels like. Design is how it works.</p>
//     <footer class="blockquote__cite">Steve Jobs</footer>
//   </div>
// </blockquote>
//
// Styleguide Components - Blockquote (Featured)

.blockquote {
  &--featured {
    padding-left: 0;
    padding-right: 0;
    border: 0;

    @include media('>=laptop') {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      // min-height: 20%;
      // padding-left: 25%;
    }

    .blockquote__image {
      display: none;

      @include media('>=laptop') {
        display: block;
        width: 20%;
      }
    }
    .blockquote__content {
      @include media('>=laptop') {
        width: 75%;
        margin-left: 5%;
      }
    }
    .blockquote__text {}
    .blockquote__cite {}
  }
}
