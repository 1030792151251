.video {
  &--featured {
    margin-left: -32px;
    margin-right: -32px;
    border-radius: radius(none);

    @include media('>=tablet') {
      margin-left: -64px;
      margin-right: -64px;
    }
    @include media('>=laptop') {
      margin-left: 0;
      margin-right: 0;
      border-radius: radius(base);
    }
  }
}
