
// Help Tooltip style
.webform-element-help:link {
  width: 18px;
}

// Legend style for fieldsets and nested fieldsets
.fieldset-legend {
  @include heading(h3);

  fieldset fieldset & {
    @include heading(h4);
  }
}

// Legend styled as a label for checkbox and radio groups
.legend-label {
  @extend %label;
}

// Multiple table webform component overrides
.webform-multiple-table {
  @extend %clearfix;
  width: 100%;

  // Dragger style
  .tabledrag-handle .handle {
    width: 28px;
    height: 28px;
    margin: 0;
  } 
  // Add/Remove row button style
  td input[type="image"] {
    width: 22px;
    height: 22px;
    display: inline-block;
  } 
  // Align add more widget
  +.container-inline {
    .form-number {
      display: inline-block;
      width: auto;
    }
  }
  .webform-multiple-tabledrag-toggle-weight {
    display: inline-block;
    padding: 8px 16px;
    background-color: rgba($primary_bg, .95);
    background-image: none;
    border: 2px solid rgba($primary_bg, .95);
    border-radius: radius(base);
    color: $primary_color;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 14px;
    transition:
      background $base-animation-speed $base-animation-easing,
      border $base-animation-speed $base-animation-easing,
      box-shadow $base-animation-speed $base-animation-easing;
    cursor: pointer;

    @include media('>=tablet') {
      width: auto;
      font-size: 16px;
    }
    @include media('>=laptop') {
      font-size: 19px;
    }

    &:link, &:visited {
      background-color: rgba($primary_bg, .95);
      border-color: rgba($primary_bg, .95);
      color: $primary_color;
      text-decoration: none;
    }
    &:focus {
      background-image: none;
      background-color: $primary_bg;
      box-shadow: 0 0 0 4px color(secondary, gold);
      border-color: $primary_bg;
      color: $primary_color;
      outline: 0;
    }
    &:hover {
      background-color: $primary_bg;
      box-shadow: 0 0 0 4px rgba($primary_bg, .15);
      border-color: $primary_bg;
      color: $primary_color;
    }
  }
}

// Alerts inside of formblocks need to have magins removed
.formblock {
  .alert {
    margin-bottom: 0;
  }
}
