%links__title {
  margin: 0 0 16px;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 16px;

  @include media('>=tablet') {
    margin: 0 0 32px;
    font-size: 19px;
  }
  @include media('>=laptop') {
    font-size: 21px;
  }
}

%links__item {
  margin: 16px 0 0;

  &:first-child {
    margin-top: 0;
  }
}

%sidebar__title {
  @extend %links__title;
}

%sidebar__item {
  @extend %links__item;
}

%hide-off-screen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  margin: 0 !important;
  overflow: hidden;
}

%disable-drag {
  user-drag: none;
  user-select: none;
}

%clearfix {
  content: '';
  display: table;
  clear: both;
}

%label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  cursor: pointer;
}

%overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(color(primary, black), .9);
  transition: opacity $base-animation-speed * 5 $base-animation-easing;
  z-index: 2;

  @include media('>=laptop') {
    display: none;
  }
}

%social-list {
  display: inline-block;
  padding-left: 40px;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 24px;
  }
}

%footer-toggle-title {
  position: relative;
  display: block;
  padding: 16px 0;
  margin: 0;
  background: none;
  border-bottom: 1px solid rgba(color(primary, black), .1);
  color: color(primary, black);

  // icon
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: url('../img/icons/i_caret-down.svg') no-repeat 50% 50% / 24px auto;
    transition: transform $base-animation-speed $base-animation-easing;
  }

  // hover state
  &:hover {
    background-color: rgba(color(primary, gray), .05);
    border-bottom: 1px solid rgba(color(primary, black), .2);
    color: lighten(color(primary, blue), 10%);
  }

  // inactive state
  &[aria-expanded='false']:after {
    transform: rotate(0deg);
  }

  // active state
  &[aria-expanded='true']:after {
    transform: rotate(180deg);
  }
}

%footer-toggle-content {
  // inactive state
  &[aria-hidden='true'] {
    display: none;
  }

  // active state
  &[aria-hidden='false'] {
    display: block;
    margin: 32px 0;
  }
}
