.footer-container .panel-group {

  // additional panel
  &.panel-group--additional .panel__content,
  &.panel-group--community .panel__content,
  &.panel-group--help-support .panel__content {
    padding: 0;
    margin-top: 8px;
    @include media('>=desktop') {
      margin-top: 32px;
    }
  }

  &.panel-group--legal .panel__content {
    padding: 0;
  }

  &.panel-group .panel__content {
    .links {
      margin-bottom: 38px;
      @include media('>=desktop') {
        margin-bottom: 0;
      }
    }
  }

  // panel
  .panel {
    &__title {
      &:after {
        @include media('>=desktop') {
          content: '';
          display: block;
          width: 85px;
          height: 2px;
          margin-top: 16px;
          background: color(primary, gold);
        }
      }

      a {
        color: color(primary, white);
        border-bottom-color: rgba(color(primary, white), .16);
        text-decoration: none;

        // hover state
        &:hover {
          color: color(primary, white);
        }

        // focus state
        &:focus {
          color: color(primary, white);
        }

        // inactive state
        &[aria-expanded='false']:after {
          background-image: url('../img/icons/i_add_white.svg');
        }

        // active state
        &[aria-expanded='true']:after {
          background-image: url('../img/icons/i_remove_white.svg');
        }
      }
    }
  }
}
