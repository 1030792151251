.form {
  width: 100%;

  // Defaults
  // ---------------------------------------------------------------------------
  &__title {
    margin: 0 0 16px;
    font: 700 19px/1.25 $font-secondary;

    @include media('>=tablet') {
      font-size: 21px;
    }

    @include media('>=laptop') {
      font-size: 24px;
    }
  }

  &__description {
    margin: 0 0 32px;
  }

  // Secondary titles
  // ---------------------------------------------------------------------------
  .title.title--secondary {
    margin-top: 32px;
    width: 100%;

    @include media('>=tablet') {
      margin-top: 48px;
    }
    @include media('>=laptop') {
      margin-top: 64px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  // Fieldsets with legends
  // ---------------------------------------------------------------------------
  .fieldset {
    margin-top: 32px;

    @include media('>=tablet') {
      margin-top: 48px;
    }
    @include media('>=laptop') {
      margin-top: 64px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    .title.title--secondary {
      margin-top: 0;

      @include media('>=tablet') {
        margin-top: 0;
      }
      @include media('>=laptop') {
        margin-top: 0;
      }

    }
  }

  > .fieldset:first-child {
    margin-top: 0;
  }

  // Field dividers
  // ---------------------------------------------------------------------------
  hr.webform-horizontal-rule {
    margin: 32px 0;
  }

  // Search form
  // ---------------------------------------------------------------------------
  &--search {
    position: fixed;
    top: 0;
    left: 64px;
    display: flex;
    width: calc(100% - 64px);
    height: 68px;
    align-items: center;
    z-index: 1;

    @include media('<laptop', 'ie11') {
      position: relative;
    }

    @include media('>=tablet') {
      left: 80px;
      width: calc(100% - 80px);
      height: 84px;
    }
    @include media('>=laptop') {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      height: 100%;
    }

    // search label
    label {
      @include hide-off-screen;
    }

    // search input
    [type='search'] {
      height: 100%;
      background-color: color(primary, white);
      padding: 0 16px 0 64px;
      border: 0;
      border-radius: 0;

      @include media('>=laptop') {
        //min-width: 480px;
        order: 2;
        transition: background $base-animation-speed $base-animation-easing;
      }

      // focus state
      &:focus {
        box-shadow: inset 0 0 0 4px color(secondary, gold);

        @include media('>=laptop') {
          background-color: rgba(color(primary, gray), .05);
          box-shadow: none;
        }
      }
    }

    // search submit
    [type='submit'] {
      position: absolute;
      left: 16px;
      width: auto;
      height: 100%;
      padding: 16px;
      background: url('../img/icons/i_search.svg') no-repeat 50% 50% / 16px auto;
      border-radius: 0;
      font-size: 0;
      line-height: 0;
      border: 0;

      @include media('<laptop', 'ie11') {
        top: 0;
      }

      @include media('>=laptop') {
        height: auto;
        order: 1;
        // IE 11 fix
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          transform: translate(0, -50%);
        }
      }

      // hover state
      &:hover {
        background-color: transparent;
      }

      // focus state
      &:focus {
        outline: 0;
        box-shadow: inset 0 0 0 3px color(secondary, gold);
      }
    }
  }

  // Search location form
  // ---------------------------------------------------------------------------
  &--search-location {
    .formblock {
      @include media('<laptop') {
        width: 100%;
      }
    }
  }

  // Search filters
  // ---------------------------------------------------------------------------
  &--search-filters {
    padding: 32px 0 0;
    margin-bottom: 32px;
    border-bottom: 2px solid color(primary, off-white);

    @include media('>=tablet') {
      margin-bottom: 48px;
    }
    @include media('>=laptop') {
      margin-bottom: 64px;
    }

    // first formblock
    .wrapper {
      margin-bottom: 32px;

      @include media('>=tablet') {
        margin-bottom: 48px;
      }
      @include media('>=laptop') {
        margin-bottom: 64px;
      }
    }

    // search container
    .wrapper .formblock {
      position: relative;
    }

    // search submit
    .wrapper button {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 32px;
      height: 32px;
      padding: 16px;
      margin-top: -16px;
      background: url('../img/icons/i_search.svg') no-repeat 50% 50% / 16px auto;
      border-radius: 0;
      font-size: 0;
      line-height: 0;
      border: 0;

      @include media('>=laptop') {
        height: auto;
        order: 1;
      }

      // hover state
      &:hover {
        background-color: transparent;
      }

      // focus state
      &:focus {
        outline: 0;
        box-shadow: inset 0 0 0 3px color(secondary, gold);
      }
    }

    // search input
    input[type='text'] {
      padding-left: 48px;

      @include media('>=laptop') {
        width: percentage(410/1280);
      }
    }

    // formblock labels
    .formblock__label {
      @extend .sr-only;
    }

    // swiper-container
    .swiper-container {
      top: 2px; // offset bottom border
      padding: 0 32px;
      margin-top: -2px; // offset bottom border

      @include media('>=tablet') {
        padding: 0 64px;
      }
      @include media('>=desktop_wide') {
        width: 1140px;
        padding: 0;
      }
      @include media('>=1680px') {
        width: 1280px;
      }
    }

    // options container
    .radios {
      margin-bottom: 0;
    }

    // options
    .radios .option {
      margin: 0 32px 0 0;
      width: auto;
      padding: 0;
      transform: translateX(0%);
    }

    // option labels
    .option__label {
      padding: 0 0 16px;
      margin: 0;
      font-size: 16px;
      white-space: nowrap;

      @include media('>=tablet') {
        font-size: 19px;
      }
      @include media('>=laptop') {
        font-size: 21px;
      }
    }

    // option icons
    .option__icon {
      display: none;
    }

    // checked options
    .option__input:checked + .option__label {
      box-shadow: inset 0 -2px 0 0 color(primary, black);
      font-weight: 700;
    }

  }

  // required style
  &-required {
    margin-right: 3px;
    &:after {
      content: '*';
      font-size: 18px;
      color: color(secondary, red);
    }
  }
}
