.chip {

  &__value {
    @extend .h3;
    margin: 0;
  }

  &__label {
    @extend .meta;
  }

  // Stat chips
  // ---------------------------------------------------------------------------
  &--stat {}

}
