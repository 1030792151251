.shortcut {

  // Shortcut to header (i.e. back to top)
  &--header {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    background: color(secondary, dark-blue) url('../img/icons/i_caret-up_white.svg') no-repeat 50% 50% / 50% auto;
    box-shadow:
      0 0 0 2px rgba(color(primary, white), .24),
      0 4px 8px 0 rgba(color(primary, black), .12),
      0 4px 8px 0 rgba(color(primary, black), .24);
    border-radius: radius(full);
    font-size: 0;
    line-height: 0;
    color: color(primary, white);
    opacity: 0;
    transform: translateY(16%);
    transition:
      box-shadow ($base-animation-speed * 2) $base-animation-easing,
      opacity ($base-animation-speed * 2) $base-animation-easing,
      transform ($base-animation-speed * 2) $base-animation-easing;
    z-index: 9999;

    @include media('>=tablet') {
      bottom: 32px;
      right: 32px;
      width: 64px;
      height: 64px;
    }

    // link state
    &:link {
      color: color(primary, white);
      transition:
        box-shadow ($base-animation-speed * 2) $base-animation-easing,
        opacity ($base-animation-speed * 2) $base-animation-easing,
        transform ($base-animation-speed * 2) $base-animation-easing;
    }

    // visited state
    &:visited {
      color: color(primary, white);
    }

    // focus state
    &:focus {
      background-color: lighten(color(primary, blue), 10%);
      box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);
      outline: 0;

      @include media('>=tablet') {
        box-shadow: 0 0 0 8px rgba(color(primary, blue), .16);
      }
    }

    // hover state
    &:hover {
      background-color: lighten(color(primary, blue), 10%);
      box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);

      @include media('>=tablet') {
        box-shadow: 0 0 0 8px rgba(color(primary, blue), .16);
      }
    }

    // active state
    &:active {
      background-color: lighten(color(primary, blue), 10%);
      box-shadow: 0 0 0 6px rgba(color(primary, blue), .24);

      @include media('>=tablet') {
        box-shadow: 0 0 0 12px rgba(color(primary, blue), .24);
      }
    }

  }

  // Shortcut visibility
  &--visible {
    opacity: 1;
    transform: translateY(0%);
  }

  // Shortcut to main content
  &--main {
    @extend %hide-off-screen;

    &:focus {
      position: fixed;
      top: 3px;
      left: 3px;
      width: auto;
      height: auto;
      padding: 8px 16px;
      background-color: color(primary, white);
      background-image: none;
      text-decoration: underline;
      outline: 3px solid color(secondary, gold);
      overflow: auto;
      z-index: 10001;
    }
  }

}
