// Links
//
// A group of links. Can optionally display a <code>$title</code>.
//
// Markup:
// <div class="links {{modifier_class}}">
//   <h3 class="links__title">Links title</h3>
//   <ul class="links__list">
//     <li class="links__item"><a href="#">Current road conditions</a></li>
//     <li class="links__item"><a href="#">NC Department of Transportation</a></li>
//     <li class="links__item"><a href="#">NC Department of Public Safety</a></li>
//   </ul>
// </div>
//
// .links--contextual   - Contextual links
//
// Styleguide Components - Links

.links {
  width: 100%;
  margin-bottom: 32px;

  @include media('>=tablet') {
    margin-bottom: 64px;
  }


  // Defaults
  // ---------------------------------------------------------------------------
  &__title {
    @extend %links__title;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    &--no-results {
      list-style: disc;
      margin: 0 0 0 40px;
    }
  }

  &__item {
    @extend %links__item;
    position: relative;

    // bullet separator
    &--bullet {
      &:before {
        @include media('>=desktop') {
          content: '\2022';
        }
      }
    }
  }


  // Social items
  // ---------------------------------------------------------------------------
  &__item--facebook {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-facebook.svg'); }
  }

  &__item--twitter {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-twitter.svg'); }
  }

  &__item--linkedin {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-linkedin.svg'); }
  }

  &__item--youtube {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-youtube.svg'); }
  }

  &__item--instagram {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-instagram.svg'); }
  }

  &__item--email {
    a { @extend %social-list; }
    a:before { background-image: url('../img/icons/i_social-email.svg'); }
  }


  // Connect
  //
  // Group of links displaying social media platforms.
  //
  // Markup:
  // <div class="links links--connect">
  //   <h4 class="links__title">Connect with us</h4>
  //   <ul class="links__list">
  //     <li class="links__item links__item--facebook">
  //       <a href="https://www.facebook.com/NCcourts/" target="_blank"><span>Facebook</span></a>
  //     </li>
  //     <li class="links__item links__item--linkedin">
  //       <a href="https://www.linkedin.com/company/north-carolina-administrative-office-of-the-courts" target="_blank"><span>LinkedIn</span></a>
  //     </li>
  //     <li class="links__item links__item--twitter">
  //       <a href="https://twitter.com/NCCourts" target="_blank"><span>Twitter</span></a>
  //     </li>
  //     <li class="links__item links__item--youtube">
  //       <a href="https://www.youtube.com/user/NorthCarolinaCourts" target="_blank"><span>Youtube</span></a>
  //     </li>
  //   </ul>
  // </div>
  //
  // Styleguide Components - Links - Connect

  &--connect {
    margin: 0;

    @include media('>=desktop') {
      width: percentage(303/1280);
      order: 2;
    }
  }


  // Connect Simple
  //
  // Simplified connect links, displaying only the platform logos.
  //
  // Markup:
  // <div class="links links--connect links--connect-simple">
  //   <h4 class="links__title">Connect with us</h4>
  //   <ul class="links__list">
  //     <li class="links__item links__item--facebook">
  //       <a href="https://www.facebook.com/NCcourts/" target="_blank"><span>Facebook</span></a>
  //     </li>
  //     <li class="links__item links__item--linkedin">
  //       <a href="https://www.linkedin.com/company/north-carolina-administrative-office-of-the-courts" target="_blank"><span>LinkedIn</span></a>
  //     </li>
  //     <li class="links__item links__item--twitter">
  //       <a href="https://twitter.com/NCCourts" target="_blank"><span>Twitter</span></a>
  //     </li>
  //     <li class="links__item links__item--youtube">
  //       <a href="https://www.youtube.com/user/NorthCarolinaCourts" target="_blank"><span>Youtube</span></a>
  //     </li>
  //   </ul>
  // </div>
  //
  // Styleguide Components - Links - Connect Simple

  &--connect-simple {
    margin: 0 0 28px;
    text-align: center;

    @include media('>=tablet') {
      margin-bottom: 32px;
    }

    @include media('>=desktop') {
      width: 100%;
      margin-bottom: 38px;
      order: inherit;
    }

    .links__list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .links__item {
      margin: 0 8px;

      @include media('>=tablet') {
        margin: 0 12px;
      }
      @include media('>=desktop') {
        margin: 0 16px;
      }
    }

    .links__item a {
      position: relative;
      display: inline-block;
      width: 36px;
      height: 36px;
      padding: 0;
      margin-right: 0;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -24px;
        display: block;
      }
    }

    .links__item a:before {
      width: 100%;
      height: 100%;
      background-size: auto 100%;
    }
    .links__item--facebook a:before { background-image: url('../img/icons/i_social-facebook-alt.svg'); }
    .links__item--linkedin a:before {}
    .links__item--twitter a:before { background-image: url('../img/icons/i_social-twitter-alt.svg'); }
    .links__item--youtube a:before { background-image: url('../img/icons/i_social-youtube-alt.png'); }
    .links__item--instagram a:before { background-image: url('../img/icons/i_social-instagram-alt.svg'); }

    .links__item span {
      @extend .sr-only;
    }
  }


  // Search quick links
  // ---------------------------------------------------------------------------
  &--search-quick {
    display: none;
    margin: 0;

    // links title
    .links__title {
      @extend .meta;
    }

    // links item
    .links__item {
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);

      @include media('>=laptop') {
        margin-top: 16px;
        border-top: 0;
      }

      a {
        display: block;
        padding: 16px 0;

        @include media('>=laptop') {
          display: inline;
          padding: 0;
        }
      }
    }

    // first links item
    .links__item:first-child {
      border-top: 0;

      @include media('>=laptop') {
        margin-top: 0;
      }
    }

    // active
    &.links--active {
      display: block;
    }

    & ~ .links--active {
      margin-top: 32px;
    }
  }


  // Search suggestion links
  // ---------------------------------------------------------------------------
  &--search-suggest {
    display: none;

    @include media('>=laptop') {
      margin-bottom: 32px;
    }

    // links title
    .links__title {
      @extend .meta;
    }

    // links item
    .links__item {
      position: relative;
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);

      @include media('>=laptop') {
        margin-top: 16px;
        border-top: 0;
      }

      i {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
      }

      svg {
        @include absolute-center;
      }

      a {
        display: block;
        padding: 16px 0 16px 32px;
        font-weight: 400;

        @include media('>=laptop') {
          display: inline-block;
          padding: 0 0 0 32px;
        }
      }
    }

    // first links item
    .links__item:first-child {
      border-top: 0;

      @include media('>=laptop') {
        margin-top: 0;
      }
    }

    // highlighted items
    mark {
      background-color: transparent;
      box-shadow: none;
      color: inherit;
      font-weight: 600;
    }

    // active
    &.links--active {
      display: block;
    }
  }


  // Search top links
  // ---------------------------------------------------------------------------
  &--search-top {
    display: none;

    @include media('>=laptop') {
      padding-top: 32px;
      margin-bottom: 32px;
      border-top: 1px solid lighten(color(primary, gray), 35%);
    }

    .links__title {
      @extend .meta;
    }

    .links__item {
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);

      @include media('>=laptop') {
        margin-top: 16px;
        border-top: 0;
      }

      a {
        display: block;
        padding: 16px 0;

        @include media('>=laptop') {
          display: inline;
          padding: 0;
        }
      }
    }

    .links__item:first-child {
      border-top: 0;

      @include media('>=laptop') {
        margin-top: 0;
      }
    }

    // active
    &.links--active {
      display: block;
    }
  }


  // Search all
  // ---------------------------------------------------------------------------
  &--search-all {
    display: none;
    margin: 0;
    font-weight: 600;

    @include media('>=laptop') {
      padding-top: 32px;
      border-top: 1px solid lighten(color(primary, gray), 35%);
    }

    // active
    &.links--active {
      display: block;
    }
  }


  // Contextual links
  // ---------------------------------------------------------------------------
  &--contextual {
    .links__title {
      position: relative;

      &:before {
        @include media('>=tablet') {
          content: '';
          position: absolute;
          top: 50%;
          left: -32px;
          display: block;
          width: 16px;
          height: 16px;
          margin-top: -8px;
          background: url('../img/icons/i_arrow-bottom.svg') no-repeat 50% 50% / 100% auto;
        }
      }
    }
  }

}
