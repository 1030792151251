.swiper-container {
  .band__part {
    height: 100%;
    margin-top: 0;
  }
}

.swiper-slide {
  padding-left: 32px;
  padding-right: 32px;
  transition: transform $base-animation-speed $base-animation-easing;
}

.swiper-slide-prev {
  transform: translateX(48px);
}
.swiper-slide-next {
  transform: translateX(-48px);
}

.swiper-slide-active {
  transform: translateX(0%);
}
