address {
  font-style: normal;
  margin: 0 0 14px;

  @include media('>=tablet') {
    margin-bottom: 16px;
  }

  @include media('>=laptop') {
    margin-bottom: 19px;
  }
}
