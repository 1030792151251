// Collapse
//
// <span class="sg-flag sg-flag-js">JS</span> Allows content to be collapsed and expanded with user interaction. This component requires <code>collapse.js</code> for dismiss functionality.
//
// Markup:
// <div class="panel-group panel-group--accordion" role="tablist" aria-multiselectable="true">
//   <div class="panel">
//     <div id="heading__panel-sample" class="panel__heading" role="tab">
//       <h4 class="panel__title"><a role="button" data-toggle="collapse" href="#panel__content-sample" aria-expanded="true" aria-controls="panel__content-sample">Collapse Group</a></h4>
//     </div>
//     <div id="panel__content-sample" class="panel__content" role="tabpanel" aria-hidden="false">
//       Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
//     </div>
//   </div>
// </div>
//
// Styleguide Components - Collapse

.panel-group {
  width: 100%;
  border-bottom: 1px solid rgba(color(primary, black), .1);

  // Panel group toggle
  &__toggle {
    @extend .button;
    @extend .button--link;
    float: right;
    margin-bottom: 16px;
  }

  .panel {
    position: static;
    top: auto;
    height: auto;
    background: none;
    box-shadow: none;
    overflow: visible;
    clear: right;

    // Panel heading
    &__heading {
      border-top: 1px solid rgba(color(primary, black), .1);
    }

    // Panel titles
    &__title {
      margin: 0;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;

      @include media('>=tablet') {
        font-size: 19px;
      }
      @include media('>=laptop') {
        font-size: 21px;
      }

      a {
        position: relative;
        display: block;
        padding: 16px 80px 16px 0;
        background: none;
        color: color(primary, black);

        // icon
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 32px;
          display: block;
          width: 16px;
          height: 16px;
          margin-top: -8px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 16px auto;
        }

        // hover state
        &:hover {
          background-color: rgba(color(primary, gray), .05);
          color: lighten(color(primary, blue), 10%);
        }

        // inactive state
        &[aria-expanded='false']:after {
          background-image: url('../img/icons/i_add.svg');
        }

        // active state
        &[aria-expanded='true']:after {
          background-image: url('../img/icons/i_remove.svg');
        }
      }
    }

    // Panel content
    &__content {

      // inactive state
      &[aria-hidden='true'] {
        display: none;
      }

      // active state
      &[aria-hidden='false'] {
        display: block;
        padding: 32px 0;

        @include media('>=tablet') {
          padding: 48px 0;
        }

        @include media('>=laptop') {
          padding: 64px 0;
        }
      }

      // links list inside collapse
      .links { margin: 0; }
    }
  }
}
