// .ui-widget.ui-widget-content {
//   border: 2px solid lighten(color(primary, gray), 35%);
// }

.ui-menu .ui-menu-item-wrapper {
  padding: 8px;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  margin: 0;
  background-color: color(secondary, off-blue);
  border: 0;
  color: lighten(color(primary, blue), 10%);
}

// UI Datepicker Trigger
// -----------------------------------------------------------------------------
.ui-datepicker-trigger {
  width: 44px !important;
  margin-left: 0 !important;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;


  &:hover {
    box-shadow: -2px 0 0 0 color(primary, blue) !important;
  }
  &:focus {
    box-shadow:
      -2px 0 0 0 color(primary, blue),
      0 0 0 4px color(secondary, gold) !important;
  }
}

.form--search-location {
  .formblock__field--inline {
    @include media('<tablet') {
      flex-wrap: wrap;
    }
  }
  .ui-autocomplete-input {
    padding-right: 24px;
    flex-basis: 100%;
    margin-bottom: 16px;

    & + .button {
       @include media('<tablet') {
        margin-left: 0;
      }
    }

    @include media('>=tablet') {
      min-width: 300px;
      margin-bottom: 0;
    }
  }

}
.ui-autocomplete {
  &.ui-menu .ui-menu-item-wrapper {
    display: block;
  }
}