.judges {
  position: relative;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  counter-reset: section;

  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
  }

  // Default item
  // ---------------------------------------------------------------------------
  .judge {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 32px 0 0;
    border-radius: radius(base);
    color: color(primary, white);
    text-align: center;
    z-index: 2;

    @include media('>=tablet') {
      margin: 32px 12px 0;
      width: 45%;
    }

    // Judge photo
    &__photo {
      margin-bottom: 32px;

      picture,img {
        display: inline-block;
        height: auto;
        width: 100%;

        @include media('>=tablet') {
          height: auto;
          max-height: 450px;
          max-width: 100%;
          width: auto;
        }

        @include media('>=laptop') {
          height: auto;
          max-height: 450px;
          max-width: 100%;
          width: auto;
        }
      }
    }

    // Judge name
    &__name {
      @extend .h6;
      margin: 0;
    }

    // first item in list
    &:first-child {
      margin: 0;

      @include media('>=tablet') {
        margin: 0 27.5%;
      }
    }
  }

  &[data-theme='supreme-court'] {
    .judge {
      margin-top: 48px;

      // item count
      &:before {
        counter-increment: section;
        content: counters(section, '.');
        position: absolute;
        top: 0;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin: -16px 0 0 -16px;
        background-color: color(secondary, dark-blue);
        border: 2px solid color(secondary, dark-blue);
        border-radius: radius(full);
        font-weight: 700;
        z-index: 2;
      }

      // first item in list
      &:first-child {
        margin: 16px 0 0;

        @include media('>=tablet') {
          margin: 16px 27.5%;
        }
      }
    }
  }
}
