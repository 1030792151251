// Meta
//
// A set of data that describes and gives information about other data.
//
// Markup:
// <div class="meta">Press release</div>
//
// Styleguide Components - Meta

.meta {
  font-size: 14px;
  line-height: 1.25;
  color: color(primary, gray);
  margin-top: -5px;
  margin-right: -16px;

  @include media('>=tablet') {
    font-size: 16px;
    line-height: 1.25;
  }

  strong {
    display: inline-block;
    margin-right: 16px;
    margin-top: 5px;
    color: color(primary, black);
  }

  span, time {
    display: inline-block;
    margin-right: 16px;
    margin-top: 5px;
  }

  .badge {
    margin-right: 16px;
    margin-top: 5px;
    font-size: inherit;
  }


  // Search results meta
  // ---------------------------------------------------------------------------
  &--search-results {
    margin-bottom: 32px;
  }
}
