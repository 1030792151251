[data-single='topic'] {

  [itemprop='mainContentOfPage'] {

    .band:first-child .pagination--topics li:first-child,
    .band:last-child .pagination--topics li:last-child {
      visibility: hidden;
    }

  }

}
