// Shot
//
// Display a promotional call-to-action for a page, person, etc. Optionally can display a prefix, suffix, and/or description.
//
// Markup:
// <div class="shot {{modifier_class}}" itemscope="" itemtype="http://schema.org/WebPage">
//   <div class="shot__photo">
//     <img src="https://picsum.photos/1024/576" alt="Caption for this photograph">
//   </div>
//   <div class="shot__content">
//     <h4 class="shot__title"><a itemprop="url" href="#">Shot title</a></h4>
//     <p class="shot__description">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live.</p>
//   </div>
// </div>
//
// .shot--horizontal   - Horizontal shot
// .shot--featured     - Featured shot
//
// Styleguide Components - Shot


// Mugshots
// -----------------------------------------------------------------------------
.shots {
  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
  }

  // 2 columns
  &[data-columns='2'] {
    .shot {
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }
      @include media('>=laptop') {
        width: percentage(630/1280);
        margin-left: percentage(20/1280);
      }

      // first part in each row
      &:nth-child(odd) {
        @include media('>=tablet') {
          margin-left: 0;
        }
      }

      // first and second items of group
      &:nth-child(1),
      &:nth-child(2) {
        @include media('>=tablet') {
          margin-top: 0;
        }
      }
    }
  }

  // 3 columns
  &[data-columns='3'] {
    .shot {
      // default part
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }

      @include media('>=laptop') {
        width: percentage(412/1280);
        margin-left: percentage(22/1280);
      }

      // every 3rd item, starting with the first element (tablet)
      &:nth-child(3n-2) {
        @include media('>=tablet') {
          width: 100%;
          margin-left: 0;
        }

        @include media('>=laptop') {
          width: percentage(412/1280);
        }
      }

      // every 3rd item, starting with the second element (tablet)
      &:nth-child(3n-1) {
        @include media('>=tablet') {
          margin-left: 0;
        }

        @include media('>=laptop') {
          margin-left: percentage(22/1280);
        }
      }

      // first row (laptop)
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include media('>=laptop') {
          margin-top: 0;
        }
      }
    }
  }

  // 4 columns
  &[data-columns='4'] {
    .shot {
      // default part
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }

      @include media('>=laptop') {
        width: percentage(303/1280);
        margin-left: percentage((68/3) /1280);
      }

      // first part in each row (tablet)
      &:nth-child(odd) {
        @include media('>=tablet') {
          margin-left: 0;
        }

        @include media('>=laptop') {
          margin-left: percentage((68/3) /1280);
        }
      }

      // first part in each row (laptop)
      &:nth-child(4n-3) {
        @include media('>=laptop') {
          margin-left: 0;
        }
      }

      // first and second items of group
      &:nth-child(1),
      &:nth-child(2) {
        @include media('>=tablet') {
          margin-top: 0;
        }
      }

      // third and fourth items of group (laptop)
      &:nth-child(3),
      &:nth-child(4) {
        @include media('>=laptop') {
          margin-top: 0;
        }
      }
    }
  }

}


// Shot
// -----------------------------------------------------------------------------
.shot {
  // IE 11 fix
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: hidden;
  }
  margin: 0 0 32px;

  // Shot photo
  &__photo {
    margin-bottom: 32px;
  }

  // Shot prefix
  &__prefix {
    @extend .meta;
    margin-bottom: 8px;
  }

  // Shot title
  &__title {
    @extend .h6;
    margin: 0;
  }

  // Shot suffix
  &__suffix {
    @extend .meta;
    margin-top: 8px;
  }

  // Shot description
  &__description {
    margin: 16px 0 0;
  }

  // Shot button
  &__button {
    @extend .button;
    margin: 32px 0 0;
  }


  // Horizontal shot
  // ---------------------------------------------------------------------------
  &--horizontal {
    @include media('>=tablet') {
      display: flex;
    }

    @include media('>=laptop') {
      align-items: center;
    }

    .shot__photo {
      @include media('>=tablet') {
        width: percentage(300/624);
      }
      @include media('>=laptop') {
        width: percentage(410/1280);
        margin: 0;
      }
    }

    .shot__content {
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }
      @include media('>=laptop') {
        width: percentage(805/1280);
        margin-left: percentage(64/1280);
      }
    }

    .shot__title {
      @extend .h5;
      margin: 0;
    }
  }


  // Featured shot
  // ---------------------------------------------------------------------------
  &--featured {
    @include media('>=laptop') {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding: 128px 64px 64px;
      background-color: color(primary, black);
      border-radius: radius(base);
      color: color(primary, white);
      overflow: hidden;
    }

    &:after {
      @include media('>=laptop') {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 24%, rgba(0,0,0,.9) 64%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 24%,rgba(0,0,0,.9) 64%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 24%,rgba(0,0,0,.9) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
        z-index: 1;
      }
    }

    .shot__photo {
      margin-left: -32px;
      margin-right: -32px;

      @include media('>=tablet') {
        margin-left: 0;
        margin-right: 0;
      }
      @include media('>=laptop') {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        picture {
          height: 100%;
        }
      }
      &--portrait {
        .featured-shot-landscape {
          @include media('>=laptop') {
            display: none;
          }
        }
        .featured-shot-portrait {
          display: none;
          @include media('>=laptop') {
            display: block;
          }
        }
      }
    }
    .shot__photo img {
      border-radius: radius(none);

      @include media('>=tablet') {
        border-radius: radius(base);
      }
      @include media('>=laptop') {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .shot__title {
      @include media('>=laptop') {
        font-size: 24px;
      }
    }
    .shot__title a {
      @include media('>=laptop') {
        color: color(primary, white);
      }

      &:hover {
        @include media('>=laptop') {
          background-color: rgba(color(primary, black), .5);
        }
      }
    }

    .shot__content {
      @include media('>=laptop') {
        position: relative;
        z-index: 2;
      }
    }

    .shot__prefix,
    .shot__suffix {
      @include media('>=laptop') {
        color: color(primary, white);
      }
    }

    .shot__button {
      @include media('>=laptop') {
        background-color: color(primary, white);
        color: color(secondary, dark-blue);
      }
    }
  }

}
