// Pagination
//
// Provide pagination links for your site or app with the multi-page pagination component.
//
// Markup:
// <nav class="pagination" role="navigation" aria-label="Page navigation">
//   <h3 class="pagination__title">Page Navigation</h3>
//   <ul class="pagination__list">
//     <li class="pagination__previous pagination--disabled">&laquo; Prev<span class="sr-only">ious page</span></li>
//     <li class="pagination__item pagination--active"><span class="sr-only">You're on page</span> 1</li>
//     <li class="pagination__item"><a href="#" aria-label="Page 2">2</a></li>
//     <li class="pagination__item"><a href="#" aria-label="Page 3">3</a></li>
//     <li class="pagination__item pagination--overflow" aria-hidden="true">&hellip;</li>
//     <li class="pagination__item"><a href="#" aria-label="Page 20">20</a></li>
//     <li class="pagination__next"><a href="#" aria-label="Next page">Next <span class="sr-only">page</span> &raquo;</a></li>
//   </ul>
// </nav>
//
// Styleguide Components - Pagination

$pagination_padding: 12px 16px;
$pagination_spacing: 0 4px;

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid lighten(color(primary, gray), 35%);
  font-size: 16px;
  line-height: 1;

  @include media('>=tablet') {
    margin-top: 48px;
  }
  @include media('>=laptop') {
    margin-top: 64px;
  }

  // title
  &__title { @extend %hide-off-screen; }

  // list container
  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  // list items
  &__item {
    display: none;
    margin: $pagination_spacing;
    border-radius: radius(base);
    line-height: 1;
    vertical-align: middle;

    @include media('>=laptop') {
      display: inline-block;
    }

    a {
      display: inline-block;
      padding: $pagination_padding;
      background: none;
      border-radius: radius(base);
      color: color(primary, blue);
      transition:
        background $base-animation-speed $base-animation-easing,
        box-shadow $base-animation-speed $base-animation-easing,
        color $base-animation-speed $base-animation-easing;
      text-decoration: none;

      &:hover {
        background-color: color(primary, off-white);
        box-shadow: 0 0 0 4px rgba(color(secondary, dark-blue), .15);
        color: lighten(color(primary, blue), 10%);
      }
    }
  }

  // previous button
  &__previous {
    display: inline-block;
    margin: $pagination_spacing;

    a {
      display: inline-block;
      padding: $pagination_padding;
      background: none;
      border-radius: radius(base);
      color: color(primary, blue);
      text-decoration: none;

      &:hover {
        background-color: color(primary, off-white);
        box-shadow: 0 0 0 4px rgba(color(secondary, dark-blue), .15);
        color: lighten(color(primary, blue), 10%);
      }
    }
  }

  // next button
  &__next {
    display: inline-block;
    margin: $pagination_spacing;

    a {
      display: inline-block;
      padding: $pagination_padding;
      background: none;
      border-radius: radius(base);
      color: color(primary, blue);
      text-decoration: none;

      &:hover {
        background-color: color(primary, off-white);
        box-shadow: 0 0 0 4px rgba(color(secondary, dark-blue), .15);
        color: lighten(color(primary, blue), 10%);
      }
    }
  }

  // state: overflow cluster
  &--overflow {
    padding: $pagination_padding;
    border: 0;
    color: color(primary, gray);
  }

  // state: active item
  &--active {
    display: inline-block;
    padding: $pagination_padding;
    background-color: color(secondary, dark-blue);
    border-color: color(secondary, dark-blue);
    color: color(primary, white);
    font-weight: 600;

    &:after {
      content: attr(data-depth);
      display: inline;
    }
  }

  // state: disabled item
  &--disabled {
    padding: $pagination_padding;
    color: color(primary, gray);
    cursor: not-allowed;
  }


  // Learn pagination
  // ---------------------------------------------------------------------------
  &--learn {
    display: block;
    padding-top: 0;
    border-top: 0;
    justify-content: none;

    a {
      line-height: 1.5;
      text-decoration: underline;
    }
    svg {
      position: absolute;
      top: 50%;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }

    .pagination__list {
      display: block;

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }

    .pagination__link-title {
      display: block;
      font-weight: 600;
    }

    .pagination__link-label {
      display: block;
    }

    .pagination__previous {
      position: relative;
      width: 100%;
      margin: 0;
      text-align: center;

      @include media('>=tablet') {
        float: left;
        width: auto;
        text-align: left;
      }

      a {
        width: 100%;
        padding-left: 56px;
        padding-right: 56px;

        @include media('>=tablet') {
          padding-right: 16px;
        }
      }
      svg { left: 16px; }
    }

    .pagination__next {
      position: relative;
      width: 100%;
      margin: 0;
      text-align: center;

      @include media('>=tablet') {
        float: right;
        width: auto;
        text-align: right;
      }

      a {
        width: 100%;
        padding-left: 56px;
        padding-right: 56px;

        @include media('>=tablet') {
          padding-left: 16px;
        }
      }
      svg { right: 16px; }
    }

    .pagination__previous + .pagination__next {
      border-top: 1px solid color(primary, gray);

      @include media('>=tablet') {
        border-top: 0;
      }
    }

  }

}
