// Closings
//
// Alert message for a facility closing. Provides options for description, hours of operation, instructions, and alternate filing.
//
// Markup:
// <div class="closing">
//   <h3 class="closing__title">Wake County Clerk of Superior Court</h3>
//   <div class="closing__meta">316 Fayetteville Street</div>
//   <div class="closing__message">
//     <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p><p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day ho</p>
//     <div class="h6">Hours of operation:</div>
//     <dl class="inline-definitions">
//       <dt>Courthouse</dt>
//       <dd>Opens at 8:00 a.m.</dd>
//       <dt>Clerk's office</dt>
//       <dd>Opens at 8:00 a.m.</dd>
//       <dt>Custom room/area</dt>
//       <dd>Closed</dd>
//       <dt>District civil</dt>
//       <dd>Closes at 8:00 a.m.</dd>
//       <dt>District criminal</dt>
//       <dd>Closes at 8:00 a.m.</dd>
//       <dt>Superior criminal</dt>
//       <dd>Closed</dd>
//     </dl>
//     <div class="h6">Instructions:</div>
//     <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.</p><p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day ho</p>
//     <div class="closing__alternate">
//       <div class="closing__alternate-location">
//         <div class="h6">Alternate filing location:</div>
//         <div itemscope="" itemtype="http://schema.org/PostalAddress">
//           <span itemprop="streetAddress">54 Church Street</span>
//           <span itemprop="addressLocality">Hayesville</span>, <span itemprop="addressRegion">North Carolina</span> <span itemprop="postalCode">28904</span>
//           <a href="https://www.google.com/maps/place/54+Church+St,+Hayesville,+NC+28904/@35.0460545,-83.8197801,17z/data=!3m1!4b1!4m5!3m4!1s0x885f276f85a20f69:0x8cbd8ed2beb53336!8m2!3d35.0460545!4d-83.8175861" target="_blank">Maps and directions</a>
//         </div>
//       </div>
//       <div class="closing__alternate-contact">
//         <div class="h6">Alternate filing contact:</div>
//         <a href="tel:(919) 123-4567">(919) 123-4567</a>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide Components - Closings

.closings-group + .closings-group {
  margin-top: 64px;

  @include media('>=tablet') {
    margin-top: 96px;
  }
  @include media('>=laptop') {
    margin-top: 128px;
  }
}

.closing {
  margin: 64px 0 0;

  // closing title
  &__title {
    @extend .h5;
    margin: 0 0 8px;
    color: color(secondary, red);
  }

  // closing meta data
  &__meta {
    @extend .meta;
  }

  // closing message
  &__message {
    margin: 32px 0 0;

    @include media('>=tablet') {
      //display: flex;
    }

    strong {
      &:after {
        @extend %clearfix;
      }
    }

    [itemprop='streetAddress'] {
      display: block;
    }

    [itemprop='postalCode'] {
      &:after {
        @extend %clearfix;
      }
    }

  }

  // alternate
  &__alternate {
    @include media('>=tablet') {
      display: flex;
    }

    // closing location
    &-location {
      @include media('>=tablet') {
        width: percentage(300/624);
      }
    }

    // closing contact
    &-contact {
      margin: 32px 0 0;

      @include media('>=tablet') {
        width: percentage(300/624);
      }
    }

    // when both location and contact exist
    &-location + &-contact {
      @include media('>=tablet') {
        margin: 0 0 0 percentage(24/624);
      }
    }

  }

  // first item
  &:first-child {
    margin-top: 0;
  }

  // last item inside alert
  *:last-child {
    margin-bottom: 0;
  }

}
