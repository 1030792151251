// card for court date form
.card.card--court-date-form {
  @extend .card--inverted;

  .card__title--or {
    margin: 16px 0;
    text-align: center;
    &:before {
      display: inline;
      content: "\2014";
      margin-right: 8px;
    }
    &:after {
      display: inline;
      content: "\2014";
      margin-left: 8px;
    }
  }

  a {
    color: color(primary, white);
    font-weight: 600;

    // hover state
    &:hover {
      color: color(secondary, dark-blue);
      background-color: color(primary, off-white);
    }

    // focus state
    &:focus {
      color: color(secondary, dark-blue);
      background-color: color(primary, off-white);
    }

    // active state
    &:active {
      color: color(primary, white);
    }
  }

  .button {
    margin-top: 16px;
    @include media('>=tablet') {
      margin: 8px 0 0 8px;
    }
  }

  @include media('>=tablet', '<laptop') {
    padding: 48px;
  }

  .card__title + .formblock {
    margin: 0 0 16px 0;
  }

  // fix inline form
  .formblock__field--inline {
    align-items: center;
    @include media('<tablet') {
      flex-wrap: wrap;
    }

    input[type='text'] {
      @include media('<tablet') {
        width: 100%;
      }
    }
    input[type='submit'] {
      @include media('<tablet') {
        width: 100%;
        margin: 8px 0 0;
      }
    }
  }
}
