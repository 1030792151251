// Select Box
//
// The HTML <code>select</code> element represents a control that provides a menu of options.
//
// Markup:
// <select id="selectID" name="selectID">
//   <option value="optionName">Select an option</option>
//   <option value="optionName">Red</option>
//   <option value="optionName">Blue</option>
//   <option value="optionName">Purple</option>
// </select>
//
// Styleguide Elements - Form - Select Box

select {
  width: 100%;
  height: 41px;
  padding: 8px 40px 8px 8px;
  background-image: url('../img/icons/i_caret-down.svg');
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: calc(100% - 8px) 50%;
  border: 2px solid color(primary, gray);
  border-radius: radius(base);
  font-size: 16px;
  appearance: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition:
    border $base-animation-speed $base-animation-easing,
    box-shadow $base-animation-speed $base-animation-easing;

  @include media('>=tablet') {
    height: 44px;
  }

  @include media('>=laptop') {
    height: 48px;
  }

  // hover state
  &:hover {
    border-color: color(primary, blue);
  }

  // focus state
  &:focus {
    border-color: color(primary, blue);
    box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);
    outline: 0;
  }
}


// Radios
//
// Radios must use the <code>fieldset</code> tag and should include <code>$input</code>, <code>$label</code>, and <code>$icon</code> fields for each option.
//
// Markup:
// <fieldset class="formblock radios">
//   <legend class="formblock__label">Choose Your Favorite</legend>
//   <div class="formblock__field">
//     <div class="option">
//       <input class="option__input" id="radio1" type="radio" name="options" value="value1">
//       <label class="option__label" for="radio1">Radio 1</label>
//       <i class="option__icon" aria-hidden="true"></i>
//     </div>
//     <div class="option">
//       <input class="option__input" id="radio2" type="radio" name="options" value="value2">
//       <label class="option__label" for="radio2">Radio 2</label>
//       <i class="option__icon" aria-hidden="true"></i>
//     </div>
//     <div class="option">
//       <input class="option__input" id="radio3" type="radio" name="options" value="value3">
//       <label class="option__label" for="radio3">Radio 3</label>
//       <i class="option__icon" aria-hidden="true"></i>
//     </div>
//   </div>
// </fieldset>
//
// Styleguide Elements - Form - Radios

.radios {

  // legend
  legend { @extend %label; }

  // options
  .option {
    position: relative;

    // option input
    &__input { @extend %hide-off-screen; }

    // option icon
    &__icon {
      position: absolute;
      top: 1px;
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid color(primary, gray);
      border-radius: radius(full);
      transition:
        border $base-animation-speed $base-animation-easing,
        box-shadow $base-animation-speed $base-animation-easing;
      z-index: 1;

      @include media('>=tablet') {
        top: 2px;
      }

      @include media('>=laptop') {
        top: 4px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
        background-color: color(primary, blue);
        border-radius: radius(full);
        opacity: 0;
        transform: scale(0);
        transition:
         transform $base-animation-speed $base-animation-easing,
         opacity $base-animation-speed $base-animation-easing;
      }

    }

    // option label
    &__label {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      font-weight: 400;
      cursor: pointer;
      z-index: 2;
    }

    //--------------------------------------------------------------------------

    // focus state
    &__input:focus + .option__label + .option__icon {
      box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);

      svg path {
        stroke-dashoffset: 0;
      }
    }

    // when hovered
    &:hover .option__icon {
      border-color: color(primary, blue);
      box-shadow: 0 0 0 4px color(secondary, off-blue);

      &:before {
        background-color: color(primary, blue);
        opacity: .25;
        transform: scale(.5);
      }
    }

    // when checked
    &__input:checked + .option__label + .option__icon {
      border-color: color(primary, blue);

      &:before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

}


// Checkboxes
//
// Checkboxes must use the <code>fieldset</code> tag and should include <code>$input</code>, <code>$label</code>, and <code>$icon</code> fields for each option.
//
// Markup:
// <fieldset class="formblock checkboxes">
//   <legend class="formblock__label">Check these out</legend>
//   <div class="formblock__field">
//     <div class="option">
//       <input class="option__input" id="checkbox1" type="checkbox">
//       <label class="option__label" for="checkbox1">Checkbox 1</label>
//       <i class="option__icon" aria-hidden="true">
//         <svg width="20px" height="20px" viewBox="0 0 20 20">
//           <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
//           <polyline points="4 11 8 15 16 6"></polyline>
//         </svg>
//       </i>
//     </div>
//     <div class="option">
//       <input class="option__input" id="checkbox2" type="checkbox">
//       <label class="option__label" for="checkbox2">Checkbox 2</label>
//       <i class="option__icon" aria-hidden="true">
//         <svg width="20px" height="20px" viewBox="0 0 20 20">
//           <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
//           <polyline points="4 11 8 15 16 6"></polyline>
//         </svg>
//       </i>
//     </div>
//     <div class="option">
//       <input class="option__input" id="checkbox3" type="checkbox">
//       <label class="option__label" for="checkbox3">Checkbox 3</label>
//       <i class="option__icon" aria-hidden="true">
//         <svg width="20px" height="20px" viewBox="0 0 20 20">
//           <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
//           <polyline points="4 11 8 15 16 6"></polyline>
//         </svg>
//       </i>
//     </div>
//   </div>
// </fieldset>
//
// Styleguide Elements - Form - Checkboxes

.checkboxes {

  // legend
  legend { @extend %label; }

  // options
  .option {
    position: relative;

    // option input
    &__input { @extend %hide-off-screen; }

    // option icon
    &__icon {
      position: absolute;
      top: 1px;
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid color(primary, gray);
      border-radius: radius(base);
      transition: box-shadow $base-animation-speed $base-animation-easing;
      z-index: 1;

      @include media('>=tablet') {
        top: 2px;
      }

      @include media('>=laptop') {
        top: 4px;
      }

      svg {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 20px;
        height: 20px;
      }

      svg path {
        fill: none;
        stroke: color(primary, blue);
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 71px;
        stroke-dashoffset: 71px;
        transition: all 0.6s ease;
      }

      svg polyline {
        fill: none;
        stroke: color(primary, white);
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 18px;
        stroke-dashoffset: 18px;
        transition: all 0.3s ease;
      }
    }

    // option label
    &__label {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      font-weight: 400;
      cursor: pointer;
      z-index: 2;
    }

    //--------------------------------------------------------------------------

    // focus state
    &__input:focus + .option__label + .option__icon {
      box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);

      svg path {
        stroke-dashoffset: 0;
      }
    }

    // hover state
    &:hover .option__icon {
      box-shadow: 0 0 0 4px color(secondary, off-blue);

      svg path {
        stroke-dashoffset: 0;
      }
    }

    // checked state
    &__input:checked + .option__label + .option__icon {
      border-color: color(primary, blue);

      svg path {
        fill: color(primary, blue);
      }

      svg polyline {
        stroke-dashoffset: 0;
      }
    }
  }


}
