// List
//
// Element that groups related content together. They're part of everyday life and come in multiple formats.
//
// Styleguide Elements - List


// Unordered List
//
// The ul element, the name for which is an abbreviation of unordered list, is used to group a collection of items together in a list, but in a way that doesn’t suggest an order of precedence or importance.
//
// Markup:
// <ul>
//     <li>Lorem ipsum dolor sit amet</li>
//     <li>Consectetur adipiscing elit</li>
//     <li>Integer molestie lorem at massa</li>
// </ul>
//
// Styleguide Elements - List - Unordered List

ul {
  margin: 0 0 14px;

  @include media('>=tablet') {
    margin-bottom: 16px;
  }

  @include media('>=laptop') {
    margin-bottom: 19px;
  }
}

li {
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}


// Ordered List
//
// The ol element is similar to the ul element in that it’s used to group a collection of items together in a list.
//
// Markup:
// <ol>
//     <li>Lorem ipsum dolor sit amet</li>
//     <li>Consectetur adipiscing elit</li>
//     <li>Integer molestie lorem at massa</li>
// </ol>
//
// Styleguide Elements - List - Ordered List

ol {
  margin: 0 0 14px;

  @include media('>=tablet') {
    margin-bottom: 16px;
  }

  @include media('>=laptop') {
    margin-bottom: 19px;
  }
}


// Definition List
//
// If you want to list a series of items that essentially have a title and a description of some kind (that is, each item has two parts), use the definition list dl element.
//
// Markup:
// <dl>
//     <dt>Definition List</dt>
//     <dd>A number of connected items or names written or printed consecutively, typically one below the other.</dd>
//     <dt>This is a term.</dt>
//     <dd>This is the definition of that term, which both live in a dl.</dd>
//     <dt>Here is another term.</dt>
//     <dd>And it gets a definition too, which is this line.</dd>
//     <dt>Here is term that shares a definition with the term below.</dt>
//     <dd>And it gets a definition too, which is this line.</dd>
// </dl>
//
// Styleguide Elements - List - Definition List

dl {
  margin: 0 0 28px;

  @include media('>=tablet') {
    margin-bottom: 32px;
  }

  @include media('>=laptop') {
    margin-bottom: 38px;
  }
}

dt {
  margin-top: 28px;
  font-weight: 600;

  @include media('>=tablet') {
    margin-top: 32px;
  }

  @include media('>=laptop') {
    margin-top: 38px;
  }

  &:first-child { margin-top: 0; }
}

dd {
  margin: 16px 0 0;
}
