// Tweet
//
// Display a tweet from Twitter.
//
// Markup:
// <div class="tweet">
//   <div class="tweet__profile">
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
//       <path fill="#1DA1F2" d="M24 4.6c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.6 2.2-2.7-1 .6-2 1-3.1 1.2-.9-1-2.2-1.6-3.6-1.6-2.7 0-4.9 2.2-4.9 4.9 0 .4 0 .8.1 1.1-4.2-.2-7.8-2.2-10.2-5.2-.5.8-.7 1.6-.7 2.5 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.2-.6v.1c0 2.4 1.7 4.4 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 2 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9.1 0 14-7.5 14-14v-.6c1-.7 1.8-1.6 2.5-2.5z"></path>
//     </svg>
//     <a href="https://twitter.com/NCCourts" target="_blank">@NCCourts</a>
//   </div>
//   <div class="tweet__message">
//     <p>Important discussion with <a href="#">@GOPLeader</a> &amp; <a href="#">@repgregwalden</a> at the Capitol about the need to properly fund our government so that our courageous troops have the resources and support they need to accomplish their mission and come home SAFE.</p>
//   </div>
//   <div class="tweet__timestamp">
//     <time datetime="2018-02-27T19:00">February 27, 2018 at 7:00 p.m.</time>
//   </div>
// </div>
//
// Styleguide Components - Tweet

.tweet {
  padding: 32px;
  margin: 0 0 28px;
  background-color: color(secondary, dark-blue);
  border-radius: radius(base);
  color: color(primary, white);

  @include media('>=tablet') {
    padding: 48px;
    margin-bottom: 32px;
  }
  @include media('>=laptop') {
    padding: 64px;
    margin-bottom: 38px;
  }


  // Links
  // ----------------------------------------------------------------------------
  a {
    // default links, not visited
    &:link {
      color: rgba(color(primary, white), .95);
    }

    // links that have been visited
    &:visited {
      color: rgba(color(primary, white), .95);
    }

    // links that are hovered
    &:hover {
      background-color: transparent;
      color: color(primary, white);
    }

    // links that are in focus
    &:focus,
    &:focus:hover {
      background-color: color(secondary, gold);
      color: color(secondary, dark-blue);
    }

    // links that are actively clicked
    &:active {
      color: color(secondary, dark-blue);
    }

    // external links
    &[target='_blank'] {
      &:after {
        background-color: lighten(color(secondary, dark-blue), 5%);
        background-image: url('../img/icons/i_external_white.svg');
      }
    }
  }


  // Profile
  // ----------------------------------------------------------------------------
  &__profile {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    a { font-weight: 700; }

  }


  // Message
  // ----------------------------------------------------------------------------
  &__message {
    margin-top: 32px;
  }


  // Timestamp
  // ----------------------------------------------------------------------------
  &__timestamp {
    margin-top: 32px;
    font-size: 14px;

    @include media('>=tablet') {
      font-size: 16px;
    }
  }

}
