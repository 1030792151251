// jQuery UI Theme
// ---------------
// Use this partial to customize the theme on top of the base _jquery-ui.scss.
// =============================================================================

// UI Datepicker
// -----------------------------------------------------------------------------
.ui-datepicker {
  padding: 8px;
  margin: 4px 0;
  background-color: color(primary, white);
  border: 1px solid rgba(color(primary, gray), .25);
  border-radius: radius(base);
  box-shadow: 0 2px 4px rgba(color(primary, black), .1);
  color: color(primary, black);

  // title
  .ui-datepicker-title {
    font-weight: 700;
  }

  // controls
  .ui-datepicker-prev,
  .ui-datepicker-next {
    top: 0;
    border: 1px dotted white;
    border-radius: radius(base);
    cursor: pointer;
  }
  .ui-datepicker-prev {
    left: 0;
    background: url('../img/icons/i_caret-left.svg') no-repeat 50% 50% / 16px auto;

  }
  .ui-datepicker-next {
    right: 0;
    background: url('../img/icons/i_caret-right.svg') no-repeat 50% 50% / 16px auto;
  }

  // header
  .ui-datepicker-header {
    padding: 0;
    border-bottom: 2px solid color(primary, white);
  }

  // table
  table {
    margin: 0;
  }

  thead th,
  thead td {
    color: color(primary, black);
    font-weight: 600;
    text-align: center;
  }

  tr:hover th,
  tr:hover td {
    background-color: inherit;
  }

  // current day
  .ui-datepicker-current-day {
    a {
      background-color: color(secondary, off-blue);
      font-weight: 600;
    }
  }

  // today
  .ui-datepicker-today {
    padding: 0;
    color: color(primary, white);

    a {
      background-color: color(secondary, red);
      border-radius: radius(base);
      color: color(primary, white);
      font-weight: 600;
    }
  }

  // links
  td span,
  td a {
    border: 1px solid transparent;
    border-radius: radius(base);
    color: color(primary, black);
    text-align: center;
    transition: none;
  }

  // hover
  .ui-state-hover {
    background-color: transparent;
    border: 1px solid rgba(color(primary, gray), .25);
    color: color(primary, black);
  }

}


// UI Datepicker Trigger
// -----------------------------------------------------------------------------
.ui-datepicker-trigger {
  @extend .button--control;
  @extend .button--calendar;
}


// UI Autocomplete
// -----------------------------------------------------------------------------
.ui-autocomplete {
  background-color: color(primary, white);
  border: 1px solid rgba(color(primary, gray), .25);
  border-radius: radius(base);
  box-shadow: 0 2px 4px rgba(color(primary, black), .1);
}
