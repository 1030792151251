// Callout
//
// Proprietary band that provides a 2 column layout for a promotional message and a graphic. Automatically inherits certain band properties.
//
// Markup:
// <div class="band band--callout">
//   <div class="wrapper">
//     <div class="band__parts">
//       <div class="band__part">
//         <h2 class="h3">Follow us on Twitter</h2>
//         <p>To stay up to date on the latest closings and advisories for your county, <a class="teaser" href="#" target="_blank">follow us on Twitter</a>. We'll also keep you in the loop on the latest news, events, and more.</p>
//       </div>
//       <div class="band__part">
//         <img src="img/icons/i_social-twitter.svg" alt="Twitter logo" />
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide Layouts - Bands - Callout

.band--callout {
  @extend [data-columns='2'];
  @extend [data-tension='8-3'];
  @extend [data-theme='off-white'];
  padding: 32px 0;

  @include media('>=tablet') {
    padding: 64px 0;
  }
  @include media('>=laptop') {
    padding: 128px 0;
  }

  // force content to be vertically centered
  .band__parts {
    align-items: center;
  }

  // 1st band part
  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }

  // 2nd band part
  .band__part:nth-child(2) {
    display: none;

    @include media('>=laptop') {
      display: block;
      text-align: center;
    }
  }

  // band image
  img {
    display: inline-block;
    width: 50%;
    height: auto;
  }
}


// Page Footnote
//
// Proprietary band that provides a single column layout for a page footnote. Automatically inherits certain band properties.
//
// Markup:
// <div class="band band--footnote">
//   <div class="wrapper">
//     <div class="band__parts">
//       <div class="band__part">
//         <small>
//           To stay up to date on the latest closings and advisories for your county, <a class="teaser" href="#" target="_blank">follow us on Twitter</a>. We'll also keep you in the loop on the latest news, events, and more.
//         </small>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide Layouts - Bands - Page Footnote

.band--footnote {
  @extend [data-theme='off-white'];
  line-height: 1.2;
  padding: 0;
  margin-top: 32px;

  @include media('>=tablet') {
    margin-top: 48px;
    padding: 0;
  }
  @include media('>=laptop') {
    margin-top: 64px;
    padding: 0;
  }

  // Only child
  &:only-child {
    padding: 0;

    @include media('>=tablet') {
      padding: 0;
    }
    @include media('>=laptop') {
      padding: 0;
    }
  }

  .band__part {
    padding: 16px;

    @include media('>=tablet') {
      padding: 24px;
    }
    @include media('>=laptop') {
      padding: 32px;
    }
  }
}


// Court date form
// -----------------------------------------------------------------------------
.band.band--court-date-form {
  @extend .band--striped;

  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }
}


// Force band part stacking on tablets
// -----------------------------------------------------------------------------
.band.band--force-tablet-stack,
.band[data-columns='2'] {

  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }
  .band__part:nth-child(2) {
    @include media('>=tablet', '<laptop') {
      width: 100%;
      margin-top: 64px;
      margin-left: 0;
    }
  }

}


// County news/related
// -----------------------------------------------------------------------------
.band.band--county-news-related {

  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }

  .band__part:nth-child(2) {
    @include media('>=tablet', '<laptop') {
      width: 100%;
      margin-left: 0;
      margin-top: 64px;
    }

    .links {
      @include media('>=tablet', '<laptop') {
        float: left;
        width: percentage(300/624);
      }
    }
    .links + .links {
      @include media('>=tablet', '<laptop') {
        float: right;
        width: percentage(300/624);
      }
    }
    .card {
      @include media('>=tablet', '<laptop') {
        clear: both;
      }
    }

  }

}


// About publications
// -----------------------------------------------------------------------------
.band.band--about-publications {

  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }

  .band__part:nth-child(2) {
    @include media('>=tablet', '<laptop') {
      width: 100%;
      margin-left: 0;
      margin-top: 64px;
    }

    .sideblock {
      @include media('>=tablet', '<laptop') {
        float: left;
        width: percentage(300/624);
        margin: 0;
      }
    }
    .sideblock + .sideblock {
      @include media('>=tablet', '<laptop') {
        float: right;
        width: percentage(300/624);
        margin: 0;
      }
    }
  }

}


// 3 zone band titles
// -----------------------------------------------------------------------------
.zones[data-columns='3'] .zone:first-child .band__title {
  margin-bottom: 16px;

  @include media('>=tablet') {
    margin-bottom: 24px;
  }
  @include media('>=laptop') {
    margin-bottom: 32px;
  }
}


// Personas band
// -----------------------------------------------------------------------------
.band--personas {
  padding: 0;

  @include media('>=laptop') {
    border-bottom: 1px solid lighten(color(primary, gray), 35%);
  }
}


// Homepage featured band
// -----------------------------------------------------------------------------
.band--home-featured {
  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      display: flex;
      width: 100%;
    }
  }
  .band__part:nth-child(2) {
    @include media('>=tablet', '<laptop') {
      width: 100%;
      margin-left: 0;
      margin-top: 64px !important;
    }
  }
  .card {
    @include media('>=tablet', '<laptop') {
      width: percentage(300/624);
    }
  }
  .card + .card {
    @include media('>=tablet', '<laptop') {
      margin-top: 0;
      margin-left: percentage(24/624);
    }
  }
}

.band--home-featured-multiple {
  + .band--home-featured-multiple {
      padding: 64px 0 0;
  }
}

// Homepage news/social band
// -----------------------------------------------------------------------------
.band--home-news-social {
  .band__part:first-child {
    @include media('>=tablet', '<laptop') {
      width: 100%;
    }
  }
  .band__part:nth-child(2) {
    @include media('>=tablet', '<laptop') {
      width: 100%;
      margin-left: 0;
      margin-top: 64px !important;
    }
    @include media('>=laptop') {
      display: flex;
      flex-direction: column;
    }
  }
  .tweet {
    @include media('>=tablet', '<laptop') {
      float: left;
      width: percentage(300/624);
    }
    @include media('>=laptop') {
      order: -2;
    }
  }
  .holiday,
  .graphic-cta {
    @include media('>=tablet', '<laptop') {
      float: right;
      clear: right;
      width: percentage(300/624);
    }
  }
  .links--connect-simple {
    @include media('>=tablet', '<laptop') {
      float: left;
      clear: both;
      width: 100%;
    }
    @include media('>=laptop') {
      margin-bottom: 38px;
      order: -1;
    }
  }
}
