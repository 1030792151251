// Link
//
// The a element is usually referred to as a link.
//
// Markup:
// <a class="{{modifier_class}}" href="#" title="link title">Text link</a>
//
// .teaser   - Increased weight for teaser links
//
// Styleguide Elements - Link

a {

  // default links, not visited
  &:link {
    color: color(primary, blue);
    transition:
      color $base-animation-speed $base-animation-easing,
      background $base-animation-speed $base-animation-easing,
      box-shadow $base-animation-speed $base-animation-easing;
  }

  // links that have been visited
  &:visited {
    color: color(secondary, purple);
  }

  // links that are hovered
  &:hover {
    background-color: color(secondary, off-blue);
    color: lighten(color(primary, blue), 10%);
  }

  // links that are in focus
  &:focus {
    outline: 0;
    background-color: color(secondary, gold);
    box-shadow: 0 0 0 4px color(secondary, gold);
    color: lighten(color(primary, blue), 10%);
    z-index: 1;
  }

  // links that are actively clicked
  &:active {
    outline: 0;
    background-color: color(secondary, gold);
    box-shadow: 0 0 0 4px color(secondary, gold);
    color: lighten(color(primary, blue), 10%);
  }


  // teaser link
  // ---------------------------------------------------------------------------
  &.teaser {
    font-weight: 600;
  }


  // external links
  // ---------------------------------------------------------------------------
  &[target='_blank'] {
    margin-right: -24px;

    &:after {
      content: '';
      vertical-align: bottom;
      display: inline-block;
      width: 24px;
      height: 24px;
      background: color(secondary, off-blue) url('../img/icons/i_external.svg') no-repeat 50% 50% / 16px auto;
      transform: translateX(-25%);
      opacity: 0;
      transition:
        opacity $base-animation-speed $base-animation-easing,
        transform $base-animation-speed $base-animation-easing;
      z-index: 0;
    }

    &:hover:after {
      transform: translateX(0%);
      opacity: 1;
      z-index: 1;
    }
  }


  // tel links
  // ---------------------------------------------------------------------------
  &[href^='tel:'] { white-space: nowrap; }

}
