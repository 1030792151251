// Image
//
// Display a photograph/graphic in the content. You can optionally configure image sources for multi-device support.
//
// Markup:
// <img src="https://unsplash.it/1440/900" alt="Scenic view of mountains and ocean" />
//
// Styleguide Elements - Image

img, picture {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: radius(base);
}


// Figure
//
// The HTML <code>figure</code> element represents self-contained content, frequently with a caption (<code>figcaption</code>), and is typically referenced as a single unit.
//
// Markup:
// <figure>
//   <img src="https://unsplash.it/1440/900" alt="Description about the figure" />
//   <figcaption>Cute kitten looking right at you</figcaption>
// </figure>
//
// Styleguide Elements - Figure

figure {
  margin: 0 0 28px;
  border-radius: radius(base);
  overflow: hidden;

  @include media('>=tablet') {
    margin-bottom: 32px;
  }

  @include media('>=laptop') {
    margin-bottom: 38px;
  }
}

figcaption {
  margin: 16px 0 0;
  color: color(primary, gray);
  font-family: $font-secondary;
  font-style: italic;
  font-size: 14px;
}


// Video
//
// <span class="sg-flag sg-flag-js">JS</span> Displays a video from an external source, such as YouTube or Vimeo, without forcing the user to leave the current web page. Videos require the <code>FitVids</code> library and need to be initialized with the <code>$('iframe[src*="youtube"]').parent().fitVids();</code> script.
//
// Markup:
// <iframe width="560" height="315" src="https://www.youtube.com/embed/3Ku6s4dcJP8" frameborder="0" allowfullscreen></iframe>
//
// Styleguide Elements - Video

iframe, video {
  margin: 0 0 14px;
  border-radius: radius(base);
  overflow: hidden;

  @include media('>=tablet') {
    margin-bottom: 16px;
  }

  @include media('>=laptop') {
    margin-bottom: 19px;
  }
}
