.hero {
  padding: 4rem 0;
  background: color(secondary, dark-blue) url('../img/p_hero.jpg') no-repeat 50% 50% / cover;
  border-top: 4px solid color(primary, gold);
  border-bottom: 4px solid color(primary, gold);
  color: color(primary, white);

  @include media('>=tablet') {
    &.band {
      padding: 64px 0;
    }
  }
  @include media('>=laptop') {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-width: 8px;
    border-bottom-width: 8px;
    min-height: calc(70vh - 5rem);
  }

  &__seal {
    width: 96px;
    height: 96px;
    margin: 0 auto;
    background-image: url('../img/l_seal-color.svg');

    @include media('>=tablet') {
      width: 128px;
      height: 128px;
    }
    @include media('>=laptop') {
      width: 192px;
      height: 192px;
    }
  }

  &__title {
    margin: 16px 0 0;
    font-size: 28px;
    font-weight: normal;
    text-align: center;

    @include media('>=tablet') {
      font-size: 32px;
    }
    @include media('>=laptop') {
      font-size: 62px;
    }
  }

  &__search {
    position: relative;
    margin: 32px 0 0;

    @include media('>=tablet') {
      margin-top: 64px;
    }
    @include media('>=laptop') {
      width: percentage(630/1280);
      margin-left: auto;
      margin-right: auto;
    }

    .formblock {
      position: relative;
    }

    .formblock__label {
      @include hide-off-screen;
    }

    .formblock__field {
      padding-left: 48px;
      border-color: color(primary, white);
    }

    [type='submit'] {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 32px;
      height: 32px;
      padding: 16px;
      margin: -16px 0 0;
      background: url('../img/icons/i_search.svg') no-repeat 50% 50% / 16px auto;
      border-radius: 0;
      font-size: 0;
      line-height: 0;
      border: 0;

      @include media('>=laptop') {
        height: auto;
        order: 1;
      }

      // hover state
      &:hover {
        background-color: transparent;
      }

      // focus state
      &:focus {
        outline: 0;
        box-shadow: inset 0 0 0 3px color(secondary, gold);
      }
    }
  }

  &__actions {
    padding: 32px 0 0;
    margin: 32px 0 0;
    border-top: 1px solid color(secondary, dark-blue);

    @include media('>=tablet') {
      padding: 0;
      margin-top: 64px;
      border-top: 0;
    }
    @include media('>=desktop') {
      position: absolute;
      top: 0;
      right: 0;
      margin: 32px 32px 0 0;
    }

    ul {
      @include reset-list;

      @include media('>=tablet') {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    li {
      @include media('>=tablet') {
        margin: 0 0 0 16px;
      }
    }
    li:first-child {
      @include media('>=tablet') {
        margin: 0;
      }
    }

    .button {
      width: 100%;
      border-color: color(secondary, cyan);
      text-align: center;

      &:hover {
        background-color: lighten(color(secondary, dark-blue), 5%);
      }
    }
  }

}
