// Titles
//
// Structured page headers that introduces the content.
//
// Styleguide Components - Titles

.title {

  // default label
  &__label {
    margin-bottom: 0;
  }

  // default description
  &__description {
    display: block;
    margin: 16px 0 0;
    color: color(primary, black);
    font-size: 16px;

    @include media('>=tablet') {
      font-size: 19px;
    }

    @include media('>=laptop') {
      font-size: 21px;
    }

    // description for person content type
    &--person {
      color: color(primary, gray);
      font-size: 14px;
      line-height: 1.4em;

      @include media('>=tablet') {
        font-size: 19px;
      }
    }
  }

  // Primary Title
  //
  // Used exclusively for page titles. Optional meta and description fields.
  //
  // Markup:
  // <div class="title title--primary">
  //   <div class="meta" itemprop="keywords">Keyword(s)</div>
  //   <h1 class="title__label" itemprop="headline">Title Headline</h1>
  //   <p class="title__description" itemprop="description">Short description that is about this title headline.</p>
  // </div>
  //
  // Styleguide Components - Titles - Primary Title

  &--primary {
    position: relative;
    margin: 32px 0;

    @include media('>=tablet') {
      margin: 64px 0;
    }
    @include media('>=desktop') {
      margin: 128px 0;
    }

    // pipe (applied to last element)
    .title__label:last-child:after,
    .title__description:last-child:after {
      content: '';
      display: block;
      width: 55px;
      height: 4px;
      margin-top: 32px;
      background-color: color(primary, gold);

      @include media('>=tablet') {
        width: 57px;
        height: 6px;
        margin-top: 64px;
      }

      @include media('>=laptop') {
        width: 85px;
        height: 8px;
      }
    }

    .title__label {
      margin-top: 0;
    }

    .meta + [itemprop='name'],
    .meta + [itemprop='headline'] {
      margin-top: 16px;
    }

    [itemprop='description'] {
      @include media('>=desktop') {
        max-width: percentage(846/1280);
      }
    }
  }

  // Secondary Title
  //
  // Helps describe content at the band/section level. Optional description field.
  //
  // Markup:
  // <div class="title title--secondary">
  //   <h2 class="title__label" itemprop="headline">Title Headline</h2>
  //   <span class="title__description" itemprop="description">Short description that is about this title headline.</span>
  // </div>
  //
  // Styleguide Components - Titles - Secondary Title

  &--secondary {
    padding-bottom: 16px;
    margin: 0 0 32px;
    border-bottom: 4px solid color(primary, black);

    @include media('>=tablet') {
      margin-bottom: 48px;
    }

    @include media('>=laptop') {
      margin-bottom: 64px;
    }

    .title__label {
      margin-top: 0;
    }
    .title__description {
      margin-top: 0;
    }
  }

  // Tertiary Title
  //
  // Helps describe content at the block/element level.
  //
  // Markup:
  // <h3 class="title title--tertiary">Title headline</h3>
  //
  // Styleguide Components - Titles - Tertiary Title

  &--tertiary {
    margin: 0;
    font: 700 19px/1.25 $font-secondary;

    @include media('>=tablet') {
      font-size: 21px;
    }

    @include media('>=laptop') {
      font-size: 24px;
    }

    + p {
      margin-top: 14px;

      @include media('>=tablet') {
        margin-top: 16px;
      }
      @include media('>=laptop') {
        margin-top: 19px;
      }
    }
  }

  // Contextual Title
  //
  // Markup:
  // <h2 class="title title--contextual"><strong>32 forms</strong> containing <span>abandoned property</span>, about <span>Estates</span></h2>
  //
  // Styleguide Components - Titles - Contextual Title

  &--contextual {
    padding-bottom: 16px;
    margin: 0 0 32px;
    border-bottom: 4px solid color(primary, black);
    font-family: $font-secondary;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.25;

    @include media('>=tablet') {
      margin-bottom: 48px;
      font-size: 19px;
    }
    @include media('>=laptop') {
      margin-bottom: 64px;
      font-size: 21px;
    }

    strong {
      display: block;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 21px;
      font-style: normal;
      line-height: 1;

      @include media('>=tablet') {
        font-size: 24px;
      }
      @include media('>=laptop') {
        font-size: 28px;
      }
    }
    span {
      font-weight: 600;
    }
  }

  // Closings Title
  //
  // Markup:
  // <div class="title title--closings">Tuesday, <span class="sr-only">:</span> <span>Wednesday, February 21, 2018</span></div>
  //
  // Styleguide Components - Titles - Closings Title

  &--closings {
    @extend .h3;
    position: relative;
    background-color: color(primary, white);
    padding: 32px 0;
    margin: 0;
    font-weight: 600;

    &.title--pinned {
      position: fixed;
      box-shadow: 0 1px 0 0 lighten(color(primary, gray), 35%);
      z-index: 100;

      @include media('<=laptop') {
        top: 80px;
      }
      @include media('<=tablet') {
        top: 65px;
      }
      @include media('>laptop', '<=1365px') {
        top: 160px;
      }
      @include media('>1365px') {
        top: 80px;
      }
    }
  }

}
