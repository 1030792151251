// Global html and body
body {
  font: 400 14px / 1.5 $font-secondary;
  color: color(primary, black);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media('>=tablet') {
    font: 400 16px / 1.5 $font-secondary;
  }

  @include media('>=laptop') {
    font: 400 19px / 1.5 $font-secondary;
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: rgba(color(primary, black), .25);
    opacity: 0;
    transition: opacity $base-animation-speed * 2 $base-animation-easing;
    z-index: 0;
  }

  // clipped
  &.clipped {
    overflow-y: hidden;

    &:before {
      width: 100%;
      height: 100%;
      opacity: 1;
      z-index: 10;
    }
  }

  // clipped
  &.clipped--mobile {
    @include media('<laptop') {
      overflow-y: hidden;
    }

    &:before {
      @include media('<laptop') {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 10;
      }
    }
  }
}

// Content selections
::selection {
  background-color: color(secondary, gold);
  color: color(primary, black);
}
