// Lists
//
// A list of data objects.
//
// Markup:
// <div class="list">
//   <h2 class="list__title">List title</h2>
//   <div class="list__items">
//     <article class="list__item">
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
//     </article>
//     <article class="list__item">
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
//     </article>
//     <article class="list__item">
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
//     </article>
//   </div>
// </div>
//
// Styleguide Components - Lists

.list {

  // Default
  // ---------------------------------------------------------------------------
  &__title {
    margin: 0;
  }

  &__title + ul {
    margin-top: 19px;
  }

  &__footnote {
    margin: 32px 0 0;
    color: color(primary, gray);
    line-height: normal;

    @include media('>=tablet') {
      margin-top: 48px;
    }

    @include media('>=laptop') {
      margin-top: 64px;
    }
  }

  &__items {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    padding: 0;
    margin: 32px 0 0;
    break-inside: avoid;
    page-break-inside: avoid;

    @include media('>=tablet') {
      margin-top: 48px;
    }

    @include media('>=laptop') {
      margin-top: 64px;
    }

    // first item
    &:first-child {
      margin-top: 0;
    }

    // last element
    *:last-child { margin-bottom: 0; }

    // icon
    i {
      position: relative;
      display: block;
      width: 55px;
      height: 55px;
      background-size: 50% auto;

      @include media('>=tablet') {
        width: 138px;
        height: 138px;
      }

      @include media('>=laptop') {
        width: 193px;
        height: 193px;
      }

      svg {
        @include absolute-center;
        width: 50%;
        height: 50%;
      }
      svg * {
        stroke: color(primary, blue);
      }
    }

    // spacing inside the item
    .meta + .list__title {
      margin: 16px 0 0;
    }

    .list__title {
      margin: 0;
      font: 700 19px/1.25 $font-secondary;

      @include media('>=tablet') {
        font-size: 21px;
      }

      @include media('>=laptop') {
        font-size: 24px;
      }
    }

    .list__title + .meta,
    .list__title + [itemprop='description'] + .meta {
      margin: 16px 0 0;
    }

    [itemprop='description'] {
      display: block;
      margin: 14px 0 0;
      color: color(primary, black);

      @include media('>=tablet') {
        margin-top: 16px;
      }
      @include media('>=laptop') {
        margin-top: 19px;
      }
    }

    [itemprop='telephone'] {
      display: inline-block;
      margin-top: 16px;
    }

    .form {
      margin-top: 16px;

      @include media('>=tablet') {
        margin-top: 24px;
      }
      @include media('>=laptop') {
        margin-top: 32px;
      }
    }


    // Promoted Lists
    // -------------------------------------------------------------------------
    &--popular {
      position: relative;
      padding: 16px 32px 32px;
      margin-left: -32px;
      margin-right: -32px;
      margin-top: 16px;
      background-color: color(secondary, off-blue);

      @include media('>=tablet') {
        margin-top: 24px;
        border-radius: radius(base);
        padding: 32px;
      }
      @include media('>=laptop') {
        margin-top: 32px;
      }

      &:before {
        @extend .badge, .badge--pill, .badge--light;
        content: 'Popular';
        @include media('<tablet') {
          margin-bottom: 16px;
        }
        @include media('>=tablet') {
          position: absolute;
          top: 32px;
          right: 32px;
        }
      }

      [itemprop='description'],
      .meta {
        color: color(primary, black);
      }
    }
  }


  // Divided Lists
  // ---------------------------------------------------------------------------
  &--divided {
    .list__items {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: color(primary, white);
        z-index: 1;
      }
    }
    .list__item {
      padding: 32px 0;
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);
    }
  }

  &--divided-condensed {
    .list__items {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: color(primary, white);
        z-index: 1;
      }
    }
    .list__item {
      padding: 16px 0;
      margin: 0;
      border-top: 1px solid lighten(color(primary, gray), 35%);
    }
  }


  // News Lists
  // ---------------------------------------------------------------------------
  &--news {
    .list__item {
      @include media('>=tablet') {
        display: flex;
      }

      .news__photo {
        display: none;

        @include media('>=tablet') {
          display: block;
          width: percentage(138/624);
        }
      }
      .news__content {
        @include media('>=tablet') {
          width: percentage(454/624);
          margin-left: percentage(32/624);
        }
      }

      [itemprop='description'] {
        @include media('<tablet') {
          @include hide-off-screen;
        }
      }
    }
  }


  // Files Lists
  // ---------------------------------------------------------------------------
  &--files {
    .list__title {
      margin: 0 0 16px;
    }

    .list__item {
      padding-left: 56px;

      @include media('>=tablet') {
        padding-left: 85px;
      }

      [itemprop='headline'] {
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;

        @include media('>=tablet') {
          font-size: 19px;
          line-height: 1.25;
        }
        @include media('>=laptop') {
          font-size: 21px;
          line-height: 1.25;
        }
      }
      [itemprop='url'] {
        word-break: break-word;
      }
    }

    // remove margins on meta
    .meta span { margin-right: 0; }
  }


  // Recognition Lists
  // ---------------------------------------------------------------------------
  &--recognition {
    .item__meta {
      @extend .meta;
    }

    .item__title {
      @extend .h6;
      margin-top: 8px;
    }

    .item__attribution {}

    .item__description {
      color: color(primary, gray);
    }
  }


  // Counties Lists
  // ---------------------------------------------------------------------------
  &--counties {
    @extend .list--divided-condensed;
    margin-top: 32px;

    @include media('>=tablet') {
      margin-top: 48px;
    }
    @include media('>=laptop') {
      margin-top: 64px;
    }

    .list__items:before {
      z-index: 1;
    }

    .list__item {
      position: relative;
      break-inside: avoid;

      @include media('>=tablet') {
        padding: 24px 0;
      }
      @include media('>=laptop') {
        padding: 32px 0;
      }
    }

    .title {
      margin: 0 0 8px;
    }

    .meta {
      display: block;
    }

    .button {
      position: absolute;
      top: 50%;
      right: 0;
      width: 48px;
      height: 48px;
      margin-top: -24px;
    }
  }


  // People Lists
  // ---------------------------------------------------------------------------
  &--people {
    .list__item {
      @include media('>=tablet') {
        position: relative;
      }
    }

    [itemprop='telephone'] {
      @include media('>=tablet') {
        position: absolute;
        top: 35px; // offset padding + title height
        right: 0;
        margin-top: 0;
        text-align: right;
      }

      @include media('>=laptop') {
        top: 36px;
      }
    }
  }


  // Search Results
  // ---------------------------------------------------------------------------
  &--search-results {
    // highlighting
    .list__description mark {
      background-color: transparent;
      box-shadow: none;
      font-weight: 700;
    }
  }


  // Personas
  // -------------------------------------------------------------------------
  &--personas {
    .list__items {
      @include media('>=laptop') {
        padding: 32px 0;
        column-rule: 1px solid lighten(color(primary, gray), 35%);
      }
    }

    .list__item {
      @include media('>=laptop') {
        padding: 0;
        border: 0;
        text-align: center;
      }
    }

    .list__title a {
      position: relative;
      display: block;
      font-size: 14px;
      font-weight: 400;

      @include media('>=tablet') {
        display: inline-block;
        font-size: 16px;
      }
      @include media('>=laptop') {
        font-size: 19px;
      }

      &:focus {
        box-shadow: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: url('../img/icons/i_arrow-right.svg') no-repeat 50% 50% / 100% auto;

        @include media('>=tablet') {
          display: none;
        }
      }
    }
  }


  // Lists that display multiple columns will need to setup its container
  // based on the data-attribute.
  &[data-columns] {
    .list__items {
      @include media('>=tablet') {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }


  // 2 Columns
  //
  // Display a list in a 2-column layout.
  //
  // Markup:
  // <div class="list" data-columns="2">
  //   <h2 class="list__title">List title</h2>
  //   <div class="list__items">
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //   </div>
  // </div>
  //
  // Styleguide Components - Lists - 2 Columns

  &[data-columns='2'] {

    .list__item {
      @include media('>=tablet') {
        width: 48.125%;
        margin-right: 3.75%;
      }
      @include media('>=desktop') {
        width: 48.9473684211%;
        margin-right: 2.1052631579%;
      }
    }

    .list__item:nth-child(even) {
      @include media('>=tablet') {
        margin-right: 0;
      }
    }

    .list__item:nth-child(2) {
      @include media('>=tablet') {
        margin-top: 0;
      }
    }

  }


  // 3 Columns
  //
  // Display a list in a 3-column layout.
  //
  // Markup:
  // <div class="list" data-columns="3">
  //   <h2 class="list__title">List title</h2>
  //   <div class="list__items">
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //   </div>
  // </div>
  //
  // Styleguide Components - Lists - 3 Columns

  &[data-columns='3'] {

    .list__item {
      @include media('>=tablet') {
        width: 48.125%;
        margin-right: 3.75%;
      }
      @include media('>=desktop') {
        width: 31.9298245614%;
        margin-right: 2.1052631579%;
      }
    }

    .list__item:nth-child(even) {
      @include media('>=tablet') {
        margin-right: 0;
      }
      @include media('>=desktop') {
        margin-right: 2.1052631579%;
      }
    }

    .list__item:nth-child(3n) {
      @include media('>=desktop') {
        margin-right: 0;
      }
    }

    .list__item:nth-child(2) {
      @include media('>=tablet') {
        margin-top: 0;
      }
    }

    .list__item:nth-child(3) {
      @include media('>=desktop') {
        margin-top: 0;
      }
    }

  }


  // 4 Columns
  //
  // Display a list in a 4-column layout.
  //
  // Markup:
  // <div class="list" data-columns="4">
  //   <h2 class="list__title">List title</h2>
  //   <div class="list__items">
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //     <article class="list__item">
  //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus nunc sed dolor imperdiet consectetur. Pellentesque dapibus orci et neque vulputate convallis.
  //     </article>
  //   </div>
  // </div>
  //
  // Styleguide Components - Lists - 4 Columns

  &[data-columns='4'] {

    .list__item {
      @include media('>=tablet') {
        width: 48.125%;
        margin-right: 3.75%;
      }
      @include media('>=desktop') {
        width: 23.4210526316%;
        margin-right: 2.1052631579%;
      }
    }

    .list__item:nth-child(even) {
      @include media('>=tablet') {
        margin-right: 0;
      }
      @include media('>=desktop') {
        margin-right: 2.1052631579%;
      }
    }

    .list__item:nth-child(4n) {
      @include media('>=desktop') {
        margin-right: 0;
      }
    }

    .list__item:nth-child(2) {
      @include media('>=tablet') {
        margin-top: 0;
      }
    }

    .list__item:nth-child(3),
    .list__item:nth-child(4) {
      @include media('>=desktop') {
        margin-top: 0;
      }
    }

  }

}


// Checklist
//
// Display a list with a series of check icons using the <code>checklist</code> class.
//
// Markup:
// <ul class="checklist">
//   <li>Reached the age of 18</li>
//   <li>Registered to vote</li>
//   <li>Voted in the election</li>
// </ul>
//
// Styleguide Components - Checklist

.checklist {
  padding: 0 0 0 32px;
  list-style: none;

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: -32px;
      display: block;
      width: 16px;
      height: 16px;
      margin-right: 16px;
      background: url('../img/icons/i_check-simple.svg') no-repeat 50% 50% / 100% auto;
    }
  }

}
