.content-container {
  overflow: hidden;

  // overlays
  &:before {
    @extend %overlay;
  }

  // Panels
  // ---------------------------------------------------------------------------
  .panel {
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: color(primary, white);
    overflow-y: auto;
    transition:
      visibility $base-animation-speed * 2 $base-animation-easing,
      transform $base-animation-speed * 2 $base-animation-easing;
    z-index: 10002;

    @include media('>=tablet') {
      box-shadow: -2px 0 8px 0 rgba( color(primary, gray), .25 );
    }

    @include media('>=laptop') {
      position: relative;
      top: auto;
      left: auto;
      padding: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      opacity: 1;
      overflow: visible;
      transition: none;
      transform: none;
      z-index: auto;
    }

    // Secondary menu panel
    // --------------------
    &--secondary-menu {
      left: 100%;
      padding: 64px 32px 32px;
      opacity: 1;
      visibility: hidden;
      z-index: 10002;

      @include media('>=tablet') {
        width: 50%;
      }

      @include media('>=laptop') {
        left: auto;
        width: 100%;
        padding: 0;
        transform: none;
        visibility: visible;
      }

      // active state
      &.panel--active {
        transform: translateX(-100%);
        visibility: visible;
      }
    }

    // Contextual menu panel
    // ---------------------
    &--contextual-menu {
      top: auto;
      bottom: 0;
      left: 0;
      height: auto;
      padding: 32px;
      opacity: 0;
      transform: translateY(100%);
      visibility: hidden;
      z-index: 1;

      @include media('>=tablet') {
        padding: 64px;
      }

      @include media('>=laptop') {
        position: absolute;
        top: 100%;
        left: auto;
        right: 0;
        bottom: auto;
        width: auto;
        height: auto;
        padding: 32px;
        margin-top: -32px;
        background-color: color(primary, white);
        border: 1px solid rgba(color(primary, gray), .25);
        border-radius: radius(base);
        box-shadow: 0 2px 4px rgba(color(primary, black), .1);
        transform: translateY(-16px);
        transition:
          opacity $base-animation-speed $base-animation-easing,
          visibility $base-animation-speed $base-animation-easing,
          transform $base-animation-speed $base-animation-easing;
      }

      // active state
      &.panel--active {
        opacity: 1;
        transform: translateY(0%);
        visibility: visible;
        z-index: 10002;

        @include media('>=laptop') {
          transform: translateY(0);
          visibility: visible;
        }
      }

      .links {
        margin: 0;

        &__item {
          margin: 0;
          border-top: 1px solid lighten(color(primary, gray), 35%);

          @include media('>=laptop') {
            margin-top: 19px;
            border-top: 0;
          }

          &:first-child {
            border-top: 0;

            @include media('>=laptop') {
              margin-top: 0;
            }
          }
        }
        &__item a {
          display: block;
          padding: 16px 0;

          @include media('>=laptop') {
            display: inline;
            padding: inherit;
          }
        }
      }

      .button {
        @include media('>=laptop') {
          display: none;
        }
      }
    }

    // Homepage search
    &--home-suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      padding: 32px;
      margin-top: 4px;
      background-color: color(primary, white);
      border-radius: radius(base);
      box-shadow: 0 2px 8px 0 rgba( color(primary, gray), .25 );
      opacity: 0;
      transform: translateY(-16px);
      transition-property: opacity, transform, visibility;
      transition-duration: 70ms, 250ms, 250ms;
      transition-timing-function: linear, cubic-bezier(.23, 1, .32, 1);
      transition-delay: 25ms, 50ms, 0ms;
      visibility: hidden;
      z-index: 100;

      @include media('>=tablet') {
        padding: 48px;
      }
      @include media('>=laptop') {
        min-width: 480px;
        max-height: 75vh;
        padding: 48px;
        overflow-y: scroll;
      }

      &.panel--active {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        transition-duration: 100ms, 150ms, 25ms;
        transition-delay: 35ms, 50ms, 25ms;
      }
    }
  }

}
