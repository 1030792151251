// Graphic CTA
//
// Display a call-to-action link using a graphic.
//
// Markup:
// <div class="graphic-cta">
//   <a class="graphic-cta__link" href="http://celebrate.nccourts.org/" title="Visit website for Celebrate North Carolina Courts" target="_blank">
//     <img class="graphic--cta__graphic" src="https://picsum.photos/640/360/?random" alt="Logo for Celebrate North Carolina Courts superimposed over courthouse columns">
//   </a>
// </div>
//
// Styleguide Components - Graphic CTA

.graphic-cta {
  margin: 0 0 28px;
  border-radius: radius(base);

  @include media('>=tablet') {
    margin-bottom: 32px;
  }

  @include media('>=laptop') {
    margin-bottom: 38px;
  }

  // cta link
  &__link,
  &__link[target="_blank"] {
    position: relative;
    display: block;
    border-radius: radius(base);
    margin-right: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(color(primary, black), .25);
      border-radius: radius(base);
      opacity: 0;
      transition: opacity $base-animation-speed $base-animation-easing;
      z-index: 1;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 100%;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  // cta graphic
  &__graphic {
    width: 100%;
    height: auto;
  }
}
