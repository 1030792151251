// Holiday
//
// Display the latest holiday and link to the holiday schedule.
//
// Markup:
// <div class="holiday">
//   <div class="holiday__header">
//     <div class="holiday__title">
//       <strong>Upcoming Judicial Branch holiday</strong>
//     </div>
//     <time class="holiday__date" datetime="2018-12-25T00:00"><span class="day">Monday</span>, <span class="date">December 25</span></time>
//   </div>
//   <div class="holiday__content">
//     <div class="holiday__name">
//       <strong>Christmas Day</strong>
//     </div>
//     <div class="holiday__link">
//       <a href="#">See our full holiday schedule</a>
//     </div>
//   </div>
// </div>
//
// Styleguide Components - Holiday

.holiday {
  margin: 0 0 28px;
  background-color: color(primary, white);
  border-radius: radius(base);
  box-shadow: 0 2px 4px rgba(color(primary, black), .16);
  text-align: center;
  overflow: hidden;
  transition: box-shadow $base-animation-speed $base-animation-easing;

  @include media('>=tablet') {
    margin-bottom: 32px;
    text-align: left;
  }

  @include media('>=laptop') {
    margin-bottom: 38px;
  }

  &__header {
    padding: 32px;
    background-color: color(secondary, cyan);
    color: color(primary, white);
  }
  &__content {
    padding: 32px;
  }

  &__title {
    font-size: 14px;

    @include media('>=laptop') {
      font-size: 16px;
    }
  }
  &__date {
    font-size: 19px;
    font-weight: 700;

    @include media('>=tablet') {
      font-size: 21px;
    }
    @include media('>=laptop') {
      font-size: 24px;
    }
  }
  &__name {
    margin-top: 0;
    font-size: 16px;

    @include media('>=tablet') {
      font-size: 19px;
    }
    @include media('>=laptop') {
      font-size: 21px;
    }
  }
  &__link {}

  // Hover state
  &:hover {
    box-shadow: 0 8px 16px rgba(color(primary, black), .16);
  }

}
