// Form Blocks
//
// Add structure to a form label and input with the <code>.formblock</code> class.
//
// Styleguide Components - Form Blocks


// Default
//
// Form blocks should always have a <code>.formblock__label</code> and <code>.formblock__field</code>.
//
// Markup:
// <div class="formblock {{modifier_class}}">
//   <label class="formblock__label" for="example-input">Example label</label>
//   <input class="formblock__field" type="text" id="example-input" placeholder="Example input">
// </div>
//
// .formblock--error   - Error
//
// Weight: -10
//
// Styleguide Components - Form Blocks - Default

.formblock {
  + .formblock {
    margin-top: 32px;
  }


  // formblock labels
  &__label {}


  // formblock clear
  &__clear {}


  // Add-on Action
  //
  // Add a prefix or suffix action to your field with the <code>.formblock__field--inline</code> modifier and the appropriate button.
  //
  // Markup:
  // <div class="formblock">
  //   <label class="formblock__label" for="example-input">Example label</label>
  //   <div class="formblock__field formblock__field--inline">
  //     <input type="submit" value="Button" />
  //     <input type="text" id="example-input" placeholder="0.00">
  //   </div>
  // </div>
  // <div class="formblock">
  //   <label class="formblock__label" for="example-input">Example label</label>
  //   <div class="formblock__field formblock__field--inline">
  //     <input type="text" id="example-input" placeholder="0.00">
  //     <input type="submit" value="Button" />
  //   </div>
  // </div>
  //
  // Styleguide Components - Form Blocks - Add-on Action

  &__addon {
    position: absolute;
    top: 10px;
    display: block;
    padding: 0;
    font-weight: 600;
    color: color(primary, gray);

    @include media('>=tablet') {}

    @include media('>=laptop') {}
  }

  // formblock fields
  &__field {

    // Label Prefix
    //
    // Add a prefix label to your field with the <code>.formblock__field--prefix</code> modifier and the <code>.formblock__addon</code> block.
    //
    // Markup:
    // <div class="formblock">
    //   <label class="formblock__label" for="example-input">Example label</label>
    //   <div class="formblock__field formblock__field--prefix">
    //     <span class="formblock__addon">$</span>
    //     <input type="text" id="example-input" placeholder="0.00">
    //   </div>
    // </div>
    //
    // Styleguide Components - Form Blocks - Label Prefix

    &--prefix {
      position: relative;

      input {
        padding-left: 32px;
      }
      .formblock__addon {
        left: 16px;
      }
    }

    // Label Suffix
    //
    // Add a suffix label to your field with the <code>.formblock__field--suffix</code> modifier and the <code>.formblock__addon</code> block.
    //
    // Markup:
    // <div class="formblock">
    //   <label class="formblock__label" for="example-input">Example label</label>
    //   <div class="formblock__field formblock__field--suffix">
    //     <input type="text" id="example-input" placeholder="0.00">
    //     <span class="formblock__addon">%</span>
    //   </div>
    // </div>
    //
    // Styleguide Components - Form Blocks - Label Suffix

    &--suffix {
      position: relative;

      input {
        padding-right: 32px;
      }
      .formblock__addon {
        right: 16px;
      }
    }

    // inline formblock fields
    &--inline {
      position: relative;
      display: flex;

      button, [type='submit'], .button {
        width: auto;

        &:not(:first-child) {
          margin-left: 8px;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

  }


  // datepicker
  // ---------------------------------------------------------------------------
  &--datepicker {

    .formblock__field {
      input {
        width: auto;
        flex-grow: 2;
      }
    }

  }


  // Helper Text
  //
  // Form blocks can optionally display helper text with the <code>.formblock__notice</code>.
  //
  // Markup:
  // <div class="formblock">
  //   <label class="formblock__label" for="example-input">Example label</label>
  //   <input class="formblock__field" type="text" id="example-input" placeholder="Example input">
  //   <small class="formblock__notice">We'll never share your email with anyone else.</small>
  // </div>
  //
  // Styleguide Components - Form Blocks - Helper Text

  &__notice {
    color: color(primary, gray);
  }

  // formblock wrapper
  &__wrapper {
    position: relative;
  }


  // formblock sizes
  // ---------------------------------------------------------------------------
  &[data-size='md'] {
    @include media('>=tablet') {
      width: 300px;
    }

    @include media('>=laptop') {
      width: 412px;
    }
  }


  // formblock limit
  // ---------------------------------------------------------------------------
  &__limit {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: color(primary, white);
    box-shadow: 0 0 0 8px color(primary, white);
    color: color(primary, gray);
    font-size: 14px;
    line-height: 1;
    text-align: right;

    @include media('>=tablet') {
      font-size: 16px;
    }
  }



  // required formblocks
  // ---------------------------------------------------------------------------
  &--required {}


  // invalid formblocks
  // ---------------------------------------------------------------------------
  &--error {

    .formblock__label {
      color: color(secondary, red);
    }

    .formblock__field {
      border-color: color(secondary, red);

      &:focus {
        box-shadow: 0 0 0 4px rgba(color(secondary, red), .16);
      }
    }

    .formblock__notice {
      color: color(secondary, red);
      font-weight: 600;
    }

  }

}
