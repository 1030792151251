.filter-panel {
  position: fixed;
  left: 0;
  top: calc(100% - 48px);
  width: 100%;
  height: 100%;
  background-color: color(primary, white);
  transition: top $base-animation-speed $base-animation-easing;
  z-index: 10000;

  // title action
  &__title {
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 0 0 48px;
    background-color: color(secondary, dark-blue);
    border: 0;
    box-shadow: 0 -1px 0 0 color(primary, white);
    font: 600 14px/1 $font-secondary;
    color: color(primary, white);
    text-align: left;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 16px;
      display: block;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      background: url('../img/icons/i_filter_white.svg') no-repeat 50% 50% / 100% auto;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      display: block;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      background: url('../img/icons/i_caret-up_white.svg') no-repeat 50% 50% / 24px auto;
    }

    // focus state
    &:focus {
      box-shadow: inset 0 0 0 2px color(secondary, gold);
      outline: 0;
    }

    // active state
    &[aria-expanded='true']:after {
      background-image: url('../img/icons/i_caret-down_white.svg');
    }

  }


  // content
  &__content {
    background-color: color(primary, white);
    height: calc(100% - 48px);
    padding: 32px;
    overflow-y: auto;

    // active state
    &[aria-hidden='false'] {}

  }


  // active state
  &--active {
    top: 0%;

    .filter-panel__title {}
    .filter-panel__content {}
  }

}
