// Table
//
// A table displays a collections of data grouped into rows. It is NOT meant to be used for layout composition.
//
// Markup:
// <table class="{{modifier_class}}">
//     <caption>Shelly's Daughters</caption>
//     <thead>
//         <tr>
//             <th scope="col">Name</th>
//             <th scope="col">Age</th>
//             <th scope="col">Birthday</th>
//         </tr>
//     </thead>
//     <tbody>
//         <tr>
//             <th scope="row">Jackie</th>
//             <td>5</td>
//             <td>April 5</td>
//         </tr>
//         <tr>
//             <th scope="row">Beth</th>
//             <td>8</td>
//             <td>January 14</td>
//         </tr>
//     </tbody>
// </table>
//
// Styleguide Elements - Table

// Global
table {
  width: 100%;
  border-collapse: collapse;
}

caption {
  color: color(primary, gray);
  text-align: left;
}

tr:hover th,
tr:hover td {
  background-color: rgba(color(primary, gray), .05);
}

// Header
thead {
  th, td {
    padding: 8px 0;
    border-bottom: 2px solid color(primary, cyan);
    font-weight: 700;
    color: color(primary, blue);
    text-align: left;
  }
}

// Body
tbody {
  th, td {
    padding: 8px 0;
    border-top: 1px solid color(primary, black);
    text-align: left;
  }

  th {
    font-weight: 600;
  }

  tr:first-child th,
  tr:first-child td {
    border-top: 0;
  }
}
