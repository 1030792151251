// Alerts
//
// Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
//
// Styleguide Components - Alerts


// Inline Alerts
//
// Markup:
// <div class="alert {{modifier_class}}" role="alert"><p>This resource has been discontinued. On August 12, 2017, we archived Form #41460B and are no longer accepting this resource. We have replaced this resource with <a href="#">Form #41460C</a>.</p></div>
//
// .alert--primary    - Primary
// .alert--secondary  - Secondary
// .alert--breaking   - Breaking
//
// Styleguide Components - Alerts - Inline Alerts


// Global Alerts
//
// <span class="sg-flag sg-flag-js">JS</span> This component requires <code>alerts.js</code> for dismiss functionality.
//
// Markup:
// <div class="alert alert--global" role="alert">
//   <div class="wrapper">
//     <div class="alert__item">
//       <div class="alert__category"><span class="badge badge--danger">Court Closings</span></div>
//       <div class="alert__message">
//         <span><strong>7 counties</strong> are currently reporting closings and/or advisories</span>
//         <a class="alert__link" href="#">View active closings</a>
//       </div>
//     </div>
//     <div class="alert__item">
//       <div class="alert__category"><span class="badge badge--info">News Alert</span></div>
//       <div class="alert__message">
//         <span>Chief Justice Martin delivers State of the Judiciary Address to NC Bar Association</span>
//         <a class="alert__link" href="#">Read news article</a>
//       </div>
//     </div>
//   </div>
//   <button class="alert__dismiss">Dismiss alert</button>
// </div>
//
// Styleguide Components - Alerts - Global Alerts

.alert {
  position: relative;
  padding: 80px 32px 32px;
  margin-bottom: 32px;
  background-color: color(primary, white);
  border-radius: radius(base);

  @include media('>=tablet') {
    margin-bottom: 48px;
  }

  @include media('>=laptop') {
    padding: 32px 32px 32px 88px;
    margin-bottom: 64px;
  }

  // Defaults
  // ---------------------------------------------------------------------------

  .wrapper {
    padding: 0;
  }

  *:last-child { margin-bottom: 0; }

  // Alert icon
  &:before {
    content: '';
    position: absolute;
    top: 32px;
    left: 32px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../img/icons/i_alert-circle-exc.svg') no-repeat 50% 50% / 100% auto;

    @include media('>=laptop') {
      top: 50%;
      margin-top: -12px;
    }
  }

  // Alert category
  &__category {
    @include media('>=tablet') {
      width: percentage(138/624);
    }

    @include media('>=desktop_wide') {
      width: percentage(130/1280);
    }
  }

  // Alert message
  &__message {
    margin-top: 8px;

    @include media('>=tablet') {
      width: percentage(462/624);
      margin: 0;
    }
    @include media('>=desktop_wide') {
      display: flex;
      justify-content: space-between;
      width: percentage(1118/1280);
    }
  }

  // Alert links
  a {
    font-weight: 600;
  }

  // Alert dismiss
  &__dismiss {
    @extend .button;
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    padding: 0;
    background: color(secondary, dark-blue) url('../img/icons/i_close_white.svg') no-repeat 50% 50% / 24px auto;
    border-radius: 0;
    transition:
      background $base-animation-speed $base-animation-easing;
    font-size: 0;
    line-height: 0;

    @include media('>=desktop_wide') {
      width: 64px;
      height: 100%;
    }

    // hover state
    &:hover {
      background-image: url('../img/icons/i_close_white.svg');
      background-color: darken(color(secondary, dark-blue), 5%);
    }

    // focus state
    &:focus {
      background-image: url('../img/icons/i_close_white.svg');
      background-color: darken(color(secondary, dark-blue), 5%);
      box-shadow: inset 0 0 0 3px color(secondary, gold);
    }
  }


  // Primary alerts
  // ---------------------------------------------------------------------------
  &--primary {
    background-color: color(secondary, dark-blue);
    color: color(primary, white);

    &:before {
      background-image: url('../img/icons/i_alert-circle-exc_white.svg');
    }

    a {
      color: color(primary, white);
      font-weight: 600;

      // hover state
      &:hover {
        background-color: darken(color(secondary, dark-blue), 5%);
      }

      // focus state
      &:focus {
        color: color(secondary, dark-blue);
      }

      // active state
      &:active {
        color: color(primary, white);
      }
    }
  }


  // Secondary alerts
  // ---------------------------------------------------------------------------
  &--secondary {
    background-color: color(primary, off-white);
    color: color(primary, black);

    a {
      color: color(primary, black);
      font-weight: 600;

      // hover state
      &:hover {
        background-color: darken(color(primary, off-white), 5%);
      }

      // focus state
      &:focus {
        color: color(primary, black);
      }

      // active state
      &:active {
        color: color(primary, black);
      }
    }
  }


  // Secondary alerts
  // ---------------------------------------------------------------------------
  &--breaking {
    background-color: color(secondary, red);
    color: color(primary, white);

    &:before {
      background-image: url('../img/icons/i_alert-circle-exc_white.svg');
    }

    a {
      color: color(primary, white);
      font-weight: 600;

      // hover state
      &:hover {
        background-color: darken(color(secondary, red), 15%);
      }

      // focus state
      &:focus {
        color: color(primary, black);
      }

      // active state
      &:active {
        color: color(primary, black);
      }
    }
  }


  // Global alerts
  // ---------------------------------------------------------------------------
  &--global {
    padding: 64px 32px 32px;
    margin: 0;
    background-color: color(secondary, dark-blue);
    border-radius: 0;
    color: color(primary, white);

    @include media('>=desktop_wide') {
      padding: 32px;
      font-size: 16px;
    }

    // Hide icon
    &:before {
      display: none;
    }

    // Links
    a {
      color: color(primary, white);
      font-weight: 600;

      // hover state
      &:hover {
        background-color: darken(color(secondary, dark-blue), 5%);
      }

      // focus state
      &:focus {
        background-color: color(secondary, gold);
        color: color(secondary, dark-blue);
      }

      // active state
      &:active {
        color: color(primary, white);
      }
    }

    // Alert items
    .alert__item {
      padding: 32px 0;
      border-top: 1px solid rgba(color(primary, white), .25);

      @include media('>=tablet') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include media('>=desktop_wide') {
        padding: 24px 0;
      }
    }

    // Alert item (first)
    .alert__item:first-child {
      padding-top: 0;
      border: 0;
    }

    // Alert item (last)
    .alert__item:last-child {
      padding-bottom: 0;
    }
  }


  // Colllapsible alerts
  // ---------------------------------------------------------------------------
  &--collapsible {
    @include media('<tablet') {
      padding-top: 32px;
    }

    .alert__label {
      display: block;
      margin: 0 48px 0 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .alert__overflow {
      @include media('<tablet') {
        max-height: 0;
        overflow: hidden;
      }

      &--active {
        @include media('<tablet') {
          max-height: 1000px;
          padding-top: 32px;
        }
      }
    }

    .alert__toggle {
      @include media('<tablet') {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 24px;
        height: 24px;
        padding: 0;
        background: no-repeat url('../img/icons/i_menu-dots.svg') 50% 50% / 100% auto;
        border: 0;
      }

      &:focus {
        @include media('<tablet') {
          box-shadow: 0 0 0 2px color(secondary, gold);
          outline: 0;
        }
      }

      span { @extend .sr-only; }

      &--active {
        @include media('<tablet') {
          background-image: url('../img/icons/i_close.svg');
        }
      }
    }

    // theme elements
    &.alert--primary .alert__toggle,
    &.alert--breaking .alert__toggle {
      @include media('<tablet') {
        background-image: url('../img/icons/i_menu-dots_white.svg');
      }
    }
    &.alert--primary .alert__toggle.alert__toggle--active,
    &.alert--breaking .alert__toggle.alert__toggle--active {
      @include media('<tablet') {
        background-image: url('../img/icons/i_close_white.svg');
      }
    }

  }

}
