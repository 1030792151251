// Button
//
// Display an emphasized action in a form, dialog, and more.
//
// Markup:
// <!-- standard button -->
// <button class="button {{modifier_class}}">Button</button>
// <!-- link with button class -->
// <a class="button {{modifier_class}}" href="#" title="link title">Button</a>
// <!-- submit input -->
// <input class="button {{modifier_class}}" type="submit" value="Button" />
//
// .button--primary               - Primary
// .button--secondary             - Secondary
// .button--tertiary              - Tertiary
// .button--primary.button--ghost - Primary Outline
// .button--link                  - Link
//
// Styleguide Components - Buttons

$primary_color: color(primary, white);
$primary_bg: color(secondary, dark-blue);
$secondary_color: color(secondary, dark-blue);
$secondary_bg: color(primary, white);
$tertiary_color: color(primary, white);
$tertiary_bg: color(secondary, cyan);
$link_color: color(primary, blue);
$link_bg: transparent;

.button {
  display: inline-block;
  padding: 8px 16px;
  background-color: rgba($primary_bg, .95);
  background-image: none;
  border: 2px solid rgba($primary_bg, .95);
  border-radius: radius(base);
  color: $primary_color;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 14px;
  transition:
    background $base-animation-speed $base-animation-easing,
    border $base-animation-speed $base-animation-easing,
    box-shadow $base-animation-speed $base-animation-easing;
  cursor: pointer;

  @include media('>=tablet') {
    width: auto;
    font-size: 16px;
  }
  @include media('>=laptop') {
    font-size: 19px;
  }

  &:link, &:visited {
    background-color: rgba($primary_bg, .95);
    border-color: rgba($primary_bg, .95);
    color: $primary_color;
    text-decoration: none;
  }
  &:focus {
    background-image: none;
    background-color: $primary_bg;
    box-shadow: 0 0 0 4px color(secondary, gold);
    border-color: $primary_bg;
    color: $primary_color;
    outline: 0;
  }
  &:hover {
    background-color: $primary_bg;
    box-shadow: 0 0 0 4px rgba($primary_bg, .15);
    border-color: $primary_bg;
    color: $primary_color;
  }
  &:active {}


  // Disabled button
  // ---------------------------------------------------------------------------
  &[disabled] {
    opacity: .65;
  }


  // Icons
  // ---------------------------------------------------------------------------
  i {
    position: relative;
    top: 50%;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: -8px 8px 0 0;
  }


  // Primary button
  // ---------------------------------------------------------------------------
  &--primary {
    background-color: rgba($primary_bg, .95);
    border-color: rgba($primary_bg, .95);
    color: $primary_color;

    &:link, &:visited {
      background-color: rgba($primary_bg, .95);
      border-color: rgba($primary_bg, .95);
      color: $primary_color;
    }
    &:focus {
      background-color: $primary_bg;
      border-color: $primary_bg;
      color: $primary_color;
    }
    &:hover {
      background-color: $primary_bg;
      box-shadow: 0 0 0 4px rgba($primary_bg, .15);
      border-color: $primary_bg;
      color: $primary_color;
    }
    &:active {}

    i > svg > * {
      fill: color(primary, white);
    }

    // Primary ghost button
    // --------------------
    &.button--ghost {
      background-color: $primary_color;
      border-color: rgba($primary_bg, .95);
      color: $primary_bg;

      &:link, &:visited {
        background-color: $primary_color;
        border-color: rgba($primary_bg, .95);
        color: $primary_bg;
      }
      &:focus {
        background-color: $primary_color;
        border-color: $primary_bg;
        color: $primary_bg;
      }
      &:hover {
        background-color: $primary_color;
        border-color: $primary_bg;
        color: $primary_bg;
      }
      &:active {}
    }
  }


  // Secondary button
  // ---------------------------------------------------------------------------
  &--secondary {
    background-color: rgba($secondary_bg, .95);
    border-color: rgba($secondary_bg, .95);
    color: $secondary_color;

    &:link, &:visited {
      background-color: rgba($secondary_bg, .95);
      border-color: rgba($secondary_bg, .95);
      color: $secondary_color;
    }
    &:focus {
      background-color: $secondary_bg;
      border-color: $secondary_bg;
      color: $secondary_color;
    }
    &:hover {
      background-color: $secondary_bg;
      box-shadow: 0 0 0 4px rgba($secondary_bg, .15);
      border-color: $secondary_bg;
      color: $secondary_color;
    }
    &:active {}
  }


  // Tertiary button
  // ---------------------------------------------------------------------------
  &--tertiary {
    background-color: rgba($tertiary_bg, .95);
    border-color: rgba($tertiary_bg, .95);
    color: $tertiary_color;

    &:link, &:visited {
      background-color: rgba($tertiary_bg, .95);
      border-color: rgba($tertiary_bg, .95);
      color: $tertiary_color;
    }
    &:focus {
      background-color: $tertiary_bg;
      border-color: $tertiary_bg;
      color: $tertiary_color;
    }
    &:hover {
      background-color: $tertiary_bg;
      box-shadow: 0 0 0 4px rgba($tertiary_bg, .15);
      border-color: $tertiary_bg;
      color: $tertiary_color;
    }
    &:active {}
  }


  // Link button
  // ---------------------------------------------------------------------------
  &--link {
    background: $link_bg;
    border-color: $link_bg;
    color: $link_color;
    text-decoration: underline;

    &:link, &:visited {
      background-color: $link_bg;
      border-color: $link_bg;
      color: $link_color;
      text-decoration: underline;
    }
    &:focus {
      background-color: $link_bg;
      border-color: $link_bg;
      color: $link_color;
      text-decoration: underline;
    }
    &:hover {
      background-color: color(secondary, off-blue);
      box-shadow: none;
      border-color: $link_bg;
      color: lighten($link_color, 10%);
      text-decoration: underline;
    }
    &:active {}
  }


  // Control buttons
  // ---------------------------------------------------------------------------
  &--control {
    width: 48px;
    height: 44px;
    padding: 0;
    background-color: color(primary, off-white);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 16px auto;
    border: 2px solid color(primary, gray);
    border-radius: radius(base);
    color: color(secondary, dark-blue);
    font-size: 0;
    line-height: 0;
    cursor: pointer;

    @include media('>=tablet') {
      height: 44px;
    }
    @include media('>=laptop') {
      height: 48px;
    }

    // link state
    &:link {
      color: color(primary, black);
      text-decoration: none;
    }

    // visited state
    &:visited {
      color: color(primary, black);
    }

    // focus state
    &:focus {
      background-color: color(primary, white);
      border-color: color(secondary, dark-blue);
      box-shadow: 0 0 0 4px color(secondary, gold);
      outline: 0;
    }

    // hover state
    &:hover {
      background-color: color(primary, off-white);
      border-color: color(secondary, dark-blue);
      color: lighten(color(primary, blue), 10%);
    }

    // active state
    &:active {}

  }


  // Secondary menus
  // ---------------------------------------------------------------------------
  &--secondary-menu {
    @extend .button--primary;
    @extend .button--ghost;
    position: relative;
    width: 100%;
    padding-right: 48px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media('>=tablet') {
      width: percentage(300/624);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      background: url('../img/icons/i_slide-left.svg') no-repeat 50% 50% / 100% auto;
    }
  }


  // Contextual menus
  // ---------------------------------------------------------------------------
  &--contextual-menu {
    position: relative;
    width: 48px;
    height: 48px;
    background-color: color(primary, white);
    border-color: color(primary, white);
    border-radius: radius(full);
    color: color(primary, blue);
    text-align: center;

    &:link, &:visited {
      background-color: color(primary, white);
      border-color: color(primary, white);
      color: color(primary, blue);
    }
    &:focus {
      background-color: color(secondary, off-blue);
      border-color: color(secondary, off-blue);
      color: color(primary, blue);
    }
    &:hover {
      background-color: color(secondary, off-blue);
      box-shadow: none;
      border-color: color(secondary, off-blue);
      color: color(primary, blue);
    }
    &:active {}

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin: -8px 0 0 -8px;
    }
  }


  // Add controls
  // ---------------------------------------------------------------------------
  &--add,
  &--add:focus {
    background-image: url('../img/icons/i_circle-add.svg');
    color: inherit;
  }
  &--add {
    width: auto;
    padding: 0 16px 0 40px;
    background-position: 16px 50%;
    font-size: inherit;
    line-height: inherit;
    text-align: left;
  }


  // Remove controls
  // ---------------------------------------------------------------------------
  &--remove,
  &--remove:focus {
    background-image: url('../img/icons/i_circle-delete.svg');
  }


  // Calendar control
  // ---------------------------------------------------------------------------
  &--calendar,
  &--calendar:focus {
    background-image: url('../img/icons/i_datepicker.svg');
  }

}


.toggle {
  @extend .button;

  &--search,
  &--menu {
    width: 64px;
    padding: 0;
    background: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;

    @include media('>=tablet') {
      width: 80px;
    }

    // hover state
    &:hover { background-color: transparent; }

    // focus state
    &:focus {
      box-shadow: 0 -4px color(secondary, cyan);
      outline: 0;
    }
  }

  &--search {
    background: url('../img/icons/i_search.svg') no-repeat 50% 50% / 20px auto;
    border: 0;
    order: 1;

    @include media('>=tablet') {
      background-size: 24px auto;
    }
    @include media('>=laptop') {
      display: none;
    }

    // hover and focus states
    &:hover,
    &:focus {
      background-image: url('../img/icons/i_search.svg');
      box-shadow: none;
    }

    &.toggle--active {
      background-color: color(secondary, dark-blue);
    }
  }

  &--menu {
    background: url('../img/icons/i_menu.svg') no-repeat 50% 50% / 24px auto;
    border: 0;
    order: 3;

    @include media('>=tablet') {
      background-size: 24px auto;
    }
    @include media('>=laptop') {
      display: none;
    }

    // hover and focus states
    &:hover,
    &:focus {
      background-image: url('../img/icons/i_menu.svg');
      box-shadow: none;
    }
  }

  &--active,
  &--active:hover,
  &--active:focus {
    background-image: url('../img/icons/i_close_white.svg');
    background-size: 24px auto;
    z-index: 10003;
  }

}


// Close secondary menus
// ---------------------------------------------------------------------------
.close--secondary-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 68px;
  background-color: transparent;
  border: 0;
  border-radius: 0;

  @include media('>=tablet') {
    width: 80px;
    height: 84px;
  }

  svg {
    @include absolute-center;
    * { fill: color(primary, white); }
  }

  span {
    @extend .sr-only;
  }
}
