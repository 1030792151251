// Screen readers only
.sr-only {
  @extend %hide-off-screen;
}

// Prevent dragging
.no-drag-select {
  user-drag: none;
  user-select: none;
  pointer-events: none;
}
