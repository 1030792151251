.copyright {
  padding-right: 48px; // offset from shortcut button (right-side)
  margin: 0;
  line-height: 1.25;
  color: color(primary, gray);

  @include media('>=tablet') {
    padding-right: 64px;
    margin-top: 48px;
  }

  @include media('>=laptop') {
    width: 100%;
    padding-right: 0;
    margin-top: 16px;
  }

  // link
  a {
    // default links, not visited
    &:link {
      color: color(primary, gray);
    }

    // links that have been visited
    &:visited {
      color: color(primary, gray);
    }

    // links that are hovered
    &:hover {
      background-color: color(secondary, off-blue);
      color: color(primary, black);
    }

    // links that are in focus
    &:focus {
      background-color: color(secondary, gold);
      box-shadow: 0 0 0 4px color(secondary, gold);
      color: color(primary, black);
    }

    // links that are actively clicked
    &:active {
      background-color: color(secondary, gold);
      box-shadow: 0 0 0 4px color(secondary, gold);
      color: color(primary, black);
    }
  }
}
