// Present when a filter panel exists on the page (mobile-only)
body.has-filter-panel {

  // offset back-to-top link
  .shortcut--header {
    bottom: 64px; // original pos. + height of panel title
  }

  // offset footer
  .footer-container {
    margin-bottom: 48px; // match height of panel title
  }

}
