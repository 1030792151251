// Progress
//
// Indicates how far along the user is in a process.
//
// Styleguide Components - Progress

$animation-offset: 187;
$animation-duration: 1000ms;


// Parent class to be used on element that will be replacing its content.
// Place the spinner inside this container to properly display a progress indicator.
.loading {
  position: relative;
  min-height: 64px;
}

.progress {

  // Spinner
  //
  // Progress indicator in a circular form. Requires the <code>svg</code> markup and the <code>.loading</code> class for its parent.
  //
  // Markup:
  // <div class="loading">
  //   <svg class="progress progress--spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
  //     <circle cx="33" cy="33" r="30"></circle>
  //   </svg>
  // </div>
  //
  // Styleguide Components - Progress - Spinner

  &--spinner {
    @include absolute-center;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
    animation: rotator $animation-duration linear infinite;

    circle {
      fill: none;
      stroke: color(secondary, dark-blue);
      stroke-width: 6px;
      stroke-dasharray: $animation-offset;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      transform-origin: center;
      animation:
        dash $animation-duration ease-in-out infinite,
    }

  }

}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes dash {
  0% { stroke-dashoffset: $animation-offset; }
  50% {
    stroke-dashoffset: $animation-offset / 4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $animation-offset;
    transform:rotate(450deg);
  }
}
