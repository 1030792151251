.formgroup {
  padding-bottom: 32px;

  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 0;
    margin-bottom: 32px;
  }

  // Formblocks
  .formblock {
    @include media('>=tablet') {
      flex-grow: 2;
      width: auto;
      margin-bottom: 0;
    }
  }

  .formblock + .formblock {
    @include media('>=tablet') {
      margin-left: 24px;
    }
  }

  // Buttons
  .button {
    margin-top: 24px;
    @include media('>=tablet') {
      margin-top: 0;
      margin-left: 24px;
    }
  }

  // Adjacent Formgroups
  + .formgroup {
    padding-top: 32px;
    border-top: 1px solid lighten(color(primary, gray), 35%);
  }

}
