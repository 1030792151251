// Adjacent sideblocks in 3rd zone
.zones[data-columns='3'] .zone:last-child .sideblock + .sideblock {
  @include media('>=tablet') {
    margin-top: 48px;
  }
  @include media('>=laptop') {
    margin-top: 64px;
  }
}

// Portrait image and vcard button in people sidebar
.zones[data-columns='3'][data-layout-people] {
  img, picture {
    width: 100%;
  }
  .button {
    width: 100%;
    text-align: center;
  }
}

// Meta labels in sidebar
.sideblock__content {
  .meta {
    margin-top: 0;
    margin-right: 0;
  }
}
