.wrapper {
  padding: 0 32px;
  margin: 0;
  width: 100%;
  
  @include media('>=tablet') {
    padding: 0 64px;
  }
  @include media('>=desktop_wide') {
    width: 1140px;
    padding: 0;
    margin: 0 auto;
  }
  @include media('>=1680px') {
    width: 1280px;
  }
}
