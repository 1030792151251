// Colors
//
// Styleguide Branding - Colors

// Primary Colors
//
// Prominent color palette that expresses the brand and foundation. Use <code>color(primary, $color)</code>.
//
// <ul class="swatches">
//   <li class="swatch">
//     <div style="background-color: #002f87;"></div>
//     <h5>Blue</h5>
//     <ul>
//       <li>#002f87</li>
//       <li><code>color(primary, blue)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #ae936c;"></div>
//     <h5>Gold</h5>
//     <ul>
//       <li>#ae936c</li>
//       <li><code>color(primary, gold)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #181919;"></div>
//     <h5>Black</h5>
//     <ul>
//       <li>#181919</li>
//       <li><code>color(primary, black)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #6f777b;"></div>
//     <h5>Gray</h5>
//     <ul>
//       <li>#6f777b</li>
//       <li><code>color(primary, gray)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #f6f6f6;"></div>
//     <h5>Off White</h5>
//     <ul>
//       <li>#f6f6f6</li>
//       <li><code>color(primary, off-white)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #fff;"></div>
//     <h5>White</h5>
//     <ul>
//       <li>#ffffff</li>
//       <li><code>color(primary, white)</code></li>
//     </ul>
//   </li>
// </ul>
//
// Styleguide Branding - Colors - Primary Colors

// Secondary Colors
//
// Supplemental accent colors to emphasize elements. Use <code>color(secondary, $color)</code>.
//
// <ul class="swatches secondary">
//   <li class="swatch">
//     <div style="background-color: #1477a9;"></div>
//     <h5>Cyan</h5>
//     <ul>
//       <li>#1477a9</li>
//       <li><code>color(secondary, cyan)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #4c2c92;"></div>
//     <h5>Purple</h5>
//     <ul>
//       <li>#4c2c92</li>
//       <li><code>color(secondary, purple)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #ffbf47;"></div>
//     <h5>Gold</h5>
//     <ul>
//       <li>#ffbf47</li>
//       <li><code>color(secondary, gold)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #dc2a2a;"></div>
//     <h5>Red</h5>
//     <ul>
//       <li>#dc2a2a</li>
//       <li><code>color(secondary, red)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #008080;"></div>
//     <h5>Green</h5>
//     <ul>
//       <li>#008080</li>
//       <li><code>color(secondary, green)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #092b3c;"></div>
//     <h5>Dark Blue</h5>
//     <ul>
//       <li>#092b3c</li>
//       <li><code>color(secondary, dark-blue)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #f2f4f9;"></div>
//     <h5>Off Blue</h5>
//     <ul>
//       <li>#f2f4f9</li>
//       <li><code>color(secondary, off-blue)</code></li>
//     </ul>
//   </li>
// </ul>
//
// Styleguide Branding - Colors - Secondary Colors

// Social Colors
//
// List of brand colors for social media platforms. Use <code>color(social, $color)</code>.
//
// <ul class="swatches secondary">
//   <li class="swatch">
//     <div style="background-color: #3b5998;"></div>
//     <h5>Facebook</h5>
//     <ul>
//       <li>#3b5998</li>
//       <li><code>color(social, facebook)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #00b6f1;"></div>
//     <h5>Twitter</h5>
//     <ul>
//       <li>#00b6f1</li>
//       <li><code>color(social, twitter)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #b31217;"></div>
//     <h5>Youtube</h5>
//     <ul>
//       <li>#b31217</li>
//       <li><code>color(social, youtube)</code></li>
//     </ul>
//   </li>
//   <li class="swatch">
//     <div style="background-color: #007bb6;"></div>
//     <h5>LinkedIn</h5>
//     <ul>
//       <li>#007bb6</li>
//       <li><code>color(social, linkedin)</code></li>
//     </ul>
//   </li>
// </ul>
//
// Styleguide Branding - Colors - Social Colors

$colors: (
  primary     : (
    blue      : #002f87,
    gold      : #ae936c,
    white     : #fff,
    gray      : #6f777b,
    off-white : #f6f6f6,
    black     : #181919,
  ),
  secondary   : (
    cyan      : #1477a9,
    purple    : #4c2c92,
    gold      : #ffbf47,
    red       : #dc143c,
    green     : #008080,
    dark-blue : #072235,
    off-blue  : #f2f4f9
  ),
  social      : (
    facebook  : #3b5998,
    twitter   : #00b6f1,
    youtube   : #b31217,
    linkedin  : #007bb6
  )
);
