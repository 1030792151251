// Bands
//
// Customizable sections of content. To allow for semantics, bands can be structured as: <code>section</code> (recommended), <code>aside</code>, or <code>div</code>.
//
// Styleguide Layouts - Bands

.band {
  padding: 32px 0 0;

  @include media('>=tablet') {
    padding: 64px 0 0;
  }
  @include media('>=laptop') {
    padding: 128px 0 0;
  }


  // First band
  &:first-child {
    padding: 0;
  }


  // Only child
  &:only-child {
    padding: 0 0 32px;

    @include media('>=tablet') {
      padding: 0 0 64px;
    }
    @include media('>=laptop') {
      padding: 0 0 128px;
    }
  }


  // Band titles
  &__title {
    @extend .h2;
    @extend .title--secondary;
    width: 100%;
    margin-top: 0;
  }


  // Band footnotes
  //
  // Bands can display an optional footnote.
  //
  // Markup:
  // <section class="band" data-theme="off-white">
  //   <div class="wrapper">
  //     <h3 class="band__title">Band Title</h3>
  //     <div class="band__parts">
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //     </div>
  //     <footer class="band__footnote">
  //       <small>
  //       These files may not be suitable for users of assistive technology. If you are having trouble accessing these files, you may <a href="/contact-technical-support?what+is+the+issue+about=Request+accessible+file">request an accessible format.</a>
  //       </small>
  //     </footer>
  //   </div>
  // </section>
  //
  // Styleguide Layouts - Bands - Band Footnotes
  &__footnote {
    border-top: 1px solid transparentize(color(primary, gray), 0.8);
    color: color(primary, gray);
    margin-top: 16px;
    padding-top: 16px;
    line-height: 1.2;

    @include media('>=tablet') {
      margin-top: 24px;
      padding-top: 24px;
    }
    @include media('>=laptop') {
      margin-top: 32px;
      padding-top: 32px;
    }
  }


  // Band Themes
  //
  // Band themes allows for background customization by using the <code>data-theme</code> modifier.
  //
  // Markup:
  // <section class="band" data-padding="lg" data-theme="{{modifier_class}}">
  //   <div class="wrapper">
  //     <h3 class="band__title">Band title</h3>
  //     <div class="band__parts">
  //       <div class="band__part">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //     </div>
  //   </div>
  // </section>
  //
  // off-white    - Off-White Theme
  //
  // Styleguide Layouts - Bands - Band Themes

  &[data-theme='off-white'] {
    background-color: color(primary, off-white);

    .meta {
      color: color(primary, black);
    }
  }


  // Band Columns
  //
  // Bands can display its content in a number of different layouts using the <code>data-columns</code> modifier.Bands must use <code>band__parts</code> class to wrap all content that will be broken into columns. Each column block must use the <code>band__part</code> class.
  //
  // Markup:
  // <section class="band" data-columns="{{modifier_class}}" data-padding="lg" data-theme="off-white">
  //   <div class="wrapper">
  //     <h3 class="band__title">Band Title</h3>
  //     <div class="band__parts">
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //     </div>
  //   </div>
  // </section>
  //
  // 2    - 2 Columns
  // 3    - 3 Columns
  // 4    - 4 Columns
  //
  // Styleguide Layouts - Bands - Band Columns

  &__parts {
    @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__part {
    width: 100%;
    margin-top: 32px;

    @include media('>=tablet') {
      margin-top: 48px;
    }

    @include media('>=laptop') {
      margin-top: 64px;
    }

    &:first-child {
      margin-top: 0;
    }

    // remove margin on first element
    > *:first-child { margin-top: 0; }

    // remove margin on last element
    > *:last-child { margin-bottom: 0; }
  }

  &[data-columns='2'] {

    .band__part {

      // default part
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }

      @include media('>=laptop') {
        width: percentage(630/1280);
        margin-left: percentage(20/1280);
      }

      // first part in each row
      &:nth-child(odd) {
        @include media('>=tablet') {
          margin-left: 0;
        }
      }

      // first and second items of group
      &:nth-child(1),
      &:nth-child(2) {
        @include media('>=tablet') {
          margin-top: 0;
        }
      }
    }

  }

  &[data-columns='3'] {

    .band__part {

      // default part
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }

      @include media('>=laptop') {
        width: percentage(412/1280);
        margin-left: percentage(22/1280);
      }

      // every 3rd item, starting with the first element (tablet)
      &:nth-child(3n-2) {
        @include media('>=tablet') {
          width: 100%;
          margin-left: 0;
        }

        @include media('>=laptop') {
          width: percentage(412/1280);
        }
      }

      // every 3rd item, starting with the second element (tablet)
      &:nth-child(3n-1) {
        @include media('>=tablet') {
          margin-left: 0;
        }

        @include media('>=laptop') {
          margin-left: percentage(22/1280);
        }
      }

      // first row (laptop)
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include media('>=laptop') {
          margin-top: 0;
        }
      }

    }

  }

  &[data-columns='4'] {

    .band__part {

      // default part
      @include media('>=tablet') {
        width: percentage(300/624);
        margin-left: percentage(24/624);
      }

      @include media('>=laptop') {
        width: percentage(303/1280);
        margin-left: percentage((68/3) /1280);
      }

      // first part in each row (tablet)
      &:nth-child(odd) {
        @include media('>=tablet') {
          margin-left: 0;
        }

        @include media('>=laptop') {
          margin-left: percentage((68/3) /1280);
        }
      }

      // first part in each row (laptop)
      &:nth-child(4n-3) {
        @include media('>=laptop') {
          margin-left: 0;
        }
      }

      // first and second items of group
      &:nth-child(1),
      &:nth-child(2) {
        @include media('>=tablet') {
          margin-top: 0;
        }
      }

      // third and fourth items of group (laptop)
      &:nth-child(3),
      &:nth-child(4) {
        @include media('>=laptop') {
          margin-top: 0;
        }
      }
    }

  }


  // Band Tension
  //
  // Bands can be distributed in an off-balanced manner using the <code>data-tension</code> data attribute. These tension settings should be applied on top of the <code>data-columns</code> data attibute.
  //
  // Markup:
  // <section class="band" data-columns="2" data-tension="{{modifier_class}}" data-padding="lg" data-theme="off-white">
  //   <div class="wrapper">
  //     <h3 class="band__title">Band Title</h3>
  //     <div class="band__parts">
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //       <div class="band__part" style="background-color:rgba(0,0,0,.04); border: 1px dashed rgba(0,0,0,.16);">
  //         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu sem blandit, ultrices quam vel, maximus ipsum. Nulla ipsum arcu, lacinia vitae finibus at, ultricies vitae turpis. Donec eu porta risus. Vestibulum tristique mollis dui ut eleifend. Aliquam bibendum dignissim pellentesque.</p>
  //       </div>
  //     </div>
  //   </div>
  // </section>
  //
  // 8-4      - 2 Columns (Two-third, One-third)
  // 8-3      - 2 Columns (Two-third, One-fourth)
  //
  // Styleguide Layouts - Bands - Band Tension

  &[data-tension='8-4'] {

    // default part (laptop)
    .band__part {
      @include media('>=laptop') {
        width: percentage(410/1280);
        margin-left: percentage(24/1280);
      }

      // first part in each row (laptop)
      &:nth-child(odd) {
        @include media('>=laptop') {
          width: percentage(845/1280);
          margin-left: 0;
        }
      }
    }

  }

  &[data-tension='8-3'] {

    // default part (laptop)
    .band__part {
      @include media('>=laptop') {
        width: percentage(303/1280);
        margin-left: percentage(132/1280);
      }

      // first part in each row (laptop)
      &:nth-child(odd) {
        @include media('>=laptop') {
          width: percentage(845/1280);
          margin-left: 0;
        }
      }
    }

  }


  &--striped,
  &--striped:first-child {
    padding-bottom: 32px;
    background: linear-gradient(to bottom, color(primary, white) 50%, color(primary, off-white) 50%);

    @include media('>=tablet') {
      padding-bottom: 64px;
    }
    @include media('>=laptop') {
      padding-bottom: 128px;
    }
  }

}


// Aside / callout bands
aside.band {
  margin-bottom: 32px; // includes base padding

  @include media('>=tablet') {
    margin-bottom: 64px;
  }
  @include media('>=desktop') {
    margin-bottom: 128px;
  }
}
aside.band.band--callout {
  padding: 32px 0 64px; // offset half-height of seal
  margin: 0;

  @include media('>=tablet') {
    padding: 64px 0 96px;
  }
  @include media('>=desktop') {
    padding: 128px 0 160px;
  }
}
