// Diagrams
//
// Visual graphic representing a linear process. Great for up to 5 steps.
//
// Markup:
// <ol class="diagram">
//   <li>Arrest</li>
//   <li>Initial hearing</li>
//   <li>Trial</li>
//   <li>Sentencing</li>
//   <li>Appeal</li>
// </ol>
//
//
// Styleguide Components - Diagrams

.diagram {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px;
  margin: 0;
  background-color: color(primary, off-white);
  border-radius: radius(base);
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  counter-reset: section;

  @include media('>=tablet') {
    padding: 48px 32px;
    font-size: 16px;
  }
  @include media('>=laptop') {
    flex-wrap: nowrap;
    padding: 64px 32px;
  }


  // Default item
  // ---------------------------------------------------------------------------
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px;
    margin: 32px 12px 0;
    background-color: color(secondary, cyan);
    border: 2px solid color(secondary, dark-blue);
    border-radius: radius(base);
    color: color(primary, white);
    text-align: center;
    z-index: 2;

    @include media('>=tablet') {}
    @include media('>=laptop') {
      margin-top: 0;
    }
    @include media('>=desktop') {
      padding: 24px;
    }

    // item count
    &:before {
      counter-increment: section;
      content: counters(section, '.');
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: -16px 0 0 -16px;
      background-color: color(secondary, dark-blue);
      border: 2px solid color(secondary, dark-blue);
      border-radius: radius(full);
      font-weight: 700;
      z-index: 2;
    }

    // first item in list
    &:first-child { margin-top: 0; }
  }

  // 2 items
  // ---------------------------------------------------------------------------
  li:nth-last-child(2):first-child,
  li:nth-last-child(2):first-child ~ li {
    @include media('>=tablet') {}
    @include media('>=laptop') {}
  }


  // 3 items
  // ---------------------------------------------------------------------------
  li:nth-last-child(3):first-child,
  li:nth-last-child(3):first-child ~ li {
    @include media('>=tablet') {}
    @include media('>=laptop') {}
  }


  // 4 items
  // ---------------------------------------------------------------------------
  li:nth-last-child(4):first-child,
  li:nth-last-child(4):first-child ~ li {
    @include media('>=laptop') {
      //width: 24%;
    }
  }


  // 5 items
  // ---------------------------------------------------------------------------
  li:nth-last-child(5):first-child,
  li:nth-last-child(5):first-child ~ li {
    @include media('>=tablet') {}
    @include media('>=laptop') {
      //width: 18%;
    }
  }


}
