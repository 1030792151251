.sideblock {
  margin-top: 32px;


  // Defaults
  // ---------------------------------------------------------------------------

  // first item
  &:first-of-type {
    margin-top: 0;
  }

  // sideblock title
  &__title {
    @extend .h6;
    margin-top: 0;
  }

  // sideblock content
  &__content {
    > *:last-child {
      margin-bottom: 0;
    }
  }


  // Link lists
  // ---------------------------------------------------------------------------
  .links {

    &__item {
      margin-top: 12px;
    }

  }


  // Menu lists
  // ---------------------------------------------------------------------------
  .menu {

    &__item {
      margin-top: 12px;
    }

  }

}
