// Tags
//
// A list of data objects.
//
// Markup:
// <ul class="tags">
//   <li class="tag">
//     <a href="#" title="Browse news articles about press releases">Press release</a>
//   </li>
//   <li class="tag">
//     <a href="#" title="Browse news articles about Wake County">Wake County</a>
//   </li>
//   <li class="tag">
//     <a href="#" title="Browse news articles about Supreme Court">Supreme Court</a>
//   </li>
// </ul>
//
// Styleguide Components - Tags

.tags {
  @include reset-list;
  margin-top: 8px;
}

.tag {
  display: block;
  margin: 4px 0 0;
  color: color(primary, black);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;

  @include media('>=tablet') {
    font-size: 19px;
  }

  @include media('>=laptop') {
    font-size: 21px;
  }

  // first tag
  &:first-child {
    margin-top: 0;
  }
}
