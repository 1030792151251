// Badges
//
// Inline labels. Context and styles can be added with <code>.badge--$modifier</code> class(es).
//
// Markup:
// <span class="badge {{modifier_class}}">Label</span>
//
// .badge--pill      - Increase rounded appearance
// .badge--primary   - Primary
// .badge--secondary - Secondary
// .badge--info      - Info
// .badge--danger    - Danger alerts
// .badge--light     - Light
//
// Styleguide Components - Badges

.badge {
  display: inline-block;
  padding: 8px;
  background-color: color(primary, black);
  border-radius: radius(base);
  color: color(primary, white);
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;

  // badge context
  &--primary {
    background-color: color(primary, blue);
  }
  &--secondary {
    background-color: color(primary, gray);
  }
  &--info {
    background-color: color(secondary, cyan);
  }
  &--danger {
    background-color: color(secondary, red);
  }
  &--light {
    background-color: color(primary, white);
    color: color(primary, black);
  }

  // badge style
  &--pill {
    padding: 7px 12px 6px;
    border-radius: radius(pill);
  }
}
