// Form
//
// Styleguide Elements - Form

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}


// Label
//
// The HTML <code>label</code> element represents a caption for an item in a user interface.
//
// Markup:
// <label for="inputID">Label</label>
//
// Styleguide Elements - Form - Label

label {
  @extend %label;
}
