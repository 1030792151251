.logo {
  margin: 0;
  font-family: $font-primary;
  font-size: 19px;
  line-height: 1.13;
  color: color(primary, blue);

  a {
    position: relative;
    display: flex;
    align-items: flex-start;
    background: none;
    color: color(primary, blue);
    text-decoration: none;

    &:before {
      content: '';
      display: block;
      width: 64px;
      height: 64px;
      margin-right: 16px;
      background: url('../img/l_seal-color.svg') no-repeat 50% 50% / 100% auto;
    }

    // visited state
    &:visited {
      color: color(primary, blue);
    }

    // hover state
    &:hover {
      color: lighten(color(primary, blue), 10%);
    }

    // focus state
    &:focus {
      box-shadow: inset 0 0 0 4px color(secondary, gold);
    }
  }

  div {
    max-width: 128px;
  }

  strong {
    display: block;
    margin: 12px 0 4px;

    + span {
      color: color(primary, black);
      font-size: 16px;
      font-style: italic;
      text-transform: lowercase;
    }
  }

  // header logo
  // ---------------------------------------------------------------------------
  &--header {
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    order: 2;

    @include media('>=laptop') {
      padding-left: 32px;
    }

    a {
      width: 160px;
      height: 100%;
      text-align: center;
      align-items: center;
      align-content: center;

      @include media('>=desktop_wide') {
        width: 100%;
        color: color(primary, blue);
      }

      &:before {
        display: none;

        @include media('>=desktop_wide') {
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }

      span {
        @include media('>=desktop_wide') {
          display: block;
          width: 160px;
          text-align: left;
        }
      }

      // hover state
      &:hover {
        color: lighten(color(primary, blue), 10%);
      }
    }
  }

  // footer logo
  // ---------------------------------------------------------------------------
  &--footer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 64px;
    height: 64px;
    margin: 0;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;

    @include media('>=tablet') {
      width: 96px;
      height: 96px;
    }

    @include media('>=laptop') {
      width: 128px;
      height: 128px;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: color(primary, white);
      border-radius: 50%;

      // visited state
      &:visited {
        color: color(primary, white);
      }

      // hover state
      &:hover {
        background-color: transparent;
      }

      // logo seal
      &:before {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 50%;
      }
    }

  }
}

.tagline {
  display: block;
  width: 100%;
  height: 32px;
  margin: 16px auto 0;
  background: url('../img/l_tagline.svg?v2') no-repeat 50% 50% / auto 100%;
  font-size: 0;
  line-height: 0;

  @include media('>=tablet') {
    max-width: 300px;
  }
  @include media('>=laptop') {
    margin: -32px auto 64px;
  }
}
