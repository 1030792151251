// Hide Off Screen
//
// Position an element off-screen for screen-readers.
//
// Markup:
// //
// // Mixin: Hide Off Screen
// // @include hide-off-screen();
// //
// // no parameters available
// //
// .element { @include hide-off-screen; }
//
// Styleguide Functions - Hide Off Screen

@mixin hide-off-screen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
}


// Show On Screen
//
// Reset a previously hidden object via @mixin hide-off-screen
//
// Markup:
// //
// // Mixin: Show On Screen
// // @include show-on-screen();
// //
// // no parameters available
// //
// .element { @include show-on-screen; }
//
// Styleguide Functions - Show On Screen

@mixin show-on-screen {
  position: inherit;
  left: inherit;
  top: inherit;
  width: inherit;
  height: inherit;
  overflow: inherit;
}



// Absolute Center
//
// Position an element vertically and horizontally centered.
//
// Markup:
// //
// // Mixin: Absolute Center
// // @include absolute-center();
// //
// // no parameters available
// // Note: parent element should use position: relative;
// //
// .element { @include absolute-center; }
//
// Styleguide Functions - Absolute Center

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}


// Vertical Center
//
// Position an element vertically centered.
//
// Markup:
// //
// // Mixin: Vertical Center
// // @include vertical-center();
// //
// // no parameters available
// // Note: parent element should use position: relative;
// //
// .element { @include vertical-center; }
//
// Styleguide Functions - Vertical Center

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}


// Horizontal Center
//
// Position an element vertically centered.
//
// Markup:
// //
// // Mixin: Horizontal Center
// // @include horizontal-center();
// //
// // no parameters available
// // Note: parent element should use position: relative;
// //
// .element { @include horizontal-center; }
//
// Styleguide Functions - Horizontal Center

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


// Reset Button
//
// Zero out button properties.
//
// Markup:
// //
// // Mixin: Reset Button
// // @include reset-button();
// //
// // no parameters available
// //
// .element { @include reset-button; }
//
// Styleguide Functions - Reset Button

@mixin reset-button {
  padding: 0;
  background: none;
  border-radius: 0;

  &:hover {
    background: none;
    box-shadow: none;
    color: inherit;
  }
}


// Reset List
//
// Zero out list properties.
//
// Markup:
// //
// // Mixin: Reset List
// // @include reset-list();
// //
// // no parameters available
// //
// .element { @include reset-list; }
//
// Styleguide Functions - Reset List

@mixin reset-list {
  padding: 0;
  margin: 0;
  list-style: none;
}


// Reset Column
//
// Resets a column's gutter margin.
//
// Markup:
// //
// // Mixin: Reset Column
// // @include reset-column();
// //
// // no parameters available
// //
// .element { @include reset-column; }
//
// Styleguide Functions - Reset Column

@mixin reset-column {
  margin-left: 0;
}


// Span Column
//
// Grid size for specific columns.
//
// Markup:
// //
// // Mixin: Span Column
// // @include span-column( $span, $total );
// //
// // $span:   'number'  required. how many columns?
// // $total:  'number'  optional. defaults to 12.
// //
// .element { @include span-column(6); }
//
// Styleguide Functions - Span Column

@mixin span-column($span, $total: 12) {

  // Base settings
  $column-base  : 85; // in pixels
  $gutter-base  : 24; // in pixels

  // Calculated sizes
  $span-size  : ($column-base * $span) + ($gutter-base * ($span - 1));
  $total-size : ($column-base * $total) + ($gutter-base * ($total - 1));

  width: percentage($span-size / $total-size);
  margin-left: percentage(($gutter-base) / $total-size);

}


// Shift Column
//
// Offset element by columns.
//
// Markup:
// //
// // Mixin: Shift Column
// // @include shift-column( $span, $total, $direction);
// //
// // $span:      'number'  required. how many columns?
// // $total:     'number'  optional. defaults to 12.
// // $direction: 'string'  optional. defaults to left
// //
// .element { @include shift-column(1, 12, left); }
//
// Styleguide Functions - Shift Column

@mixin shift-column($span, $total: 12, $direction: 'left') {

  // Base settings
  $column-base  : 85; // in pixels
  $gutter-base  : 24; // in pixels

  // Calculated sizes
  $span-size  : ($column-base * $span) + ($gutter-base * ($span - 1));
  $total-size : ($column-base * $total) + ($gutter-base * ($total - 1));
  $gutter-orig: percentage($gutter-base / $total-size);

  @if $direction == 'right' {
    margin-right: $gutter-orig + percentage($span-size / $total-size);
  } @else {
    margin-left: ($gutter-orig * 2) + percentage($span-size / $total-size);

    &:first-child {
      margin-left: $gutter-orig + percentage($span-size / $total-size);
    }
  }

}


// Reset Footer Title
//
// Convert collapse titles to standard for tablets+.
//
// Markup:
// //
// // Mixin: Reset Footer Title
// // @include reset-footer-title();
// //
// .element { @include reset-footer-title(); }
//
// Styleguide Functions - Reset Footer Title

@mixin reset-footer-title() {
  .panel__title a {
    @include media('>=desktop') {
      pointer-events: none;
      display: inline-block;
      padding: 0;
      border: 0;
      text-decoration: none;
    }

    &:after {
      @include media('>=desktop') {
        display: none;
      }
    }
  }
}


// Icon Link
//
// Create link block with custom icon.
//
// Markup:
// //
// // Mixin: Icon Link
// // @include icon-link( $color );
// //
// // $color: 'string'  optional color value to fill icon
// //
// .element { @include icon-link( #c00 ); }
//
// Styleguide Functions - Icon Link

@mixin icon-link($color:null) {
  i {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    vertical-align: middle;
  }
  i > * {
    @include absolute-center;
    max-width: 24px;
  }

  @if $color != null {
    svg * { fill: $color; }
  }
  a {
    display: inline;
    vertical-align: middle;
  }
}


// Heading
//
// Apply styles to a specific heading size.
//
// Markup:
// //
// // Mixin: Heading
// // @include heading( $level );
// //
// // $level: 'string'
// //
// .element { @include heading( h1 ); }
//
// Styleguide Functions - Heading

@mixin heading($level) {

  // Heading 1
  @if $level == 'h1' {
    display: block;
    margin: 64px 0 32px;
    font: 700 32px / 1.25 $font-primary;

    @include media('>=tablet') {
      margin: 96px 0 48px;
      font-size: 48px;
    }
    @include media('>=laptop') {
      margin: 124px 0 62px;
      font-size: 62px;
    }
  }

  // Heading 2
  @if $level == 'h2' {
    display: block;
    margin: 48px 0 24px;
    font: 700 24px / 1.25 $font-secondary;

    @include media('>=tablet') {
      margin: 56px 0 28px;
      font-size: 28px;
    }
    @include media('>=laptop') {
      margin: 64px 0 32px;
      font-size: 32px;
    }
  }

  // Heading 3
  @if $level == 'h3' {
    display: block;
    margin: 42px 0 21px;
    font: 600 21px / 1.25 $font-secondary;

    @include media('>=tablet') {
      margin: 48px 0 24px;
      font-size: 24px;
    }
    @include media('>=laptop') {
      margin: 56px 0 28px;
      font-size: 28px;
    }
  }

  // Heading 4
  @if $level == 'h4' {
    display: block;
    margin: 28px 0 19px;
    font: 400 19px / 1.25 $font-secondary;

    @include media('>=tablet') {
      margin: 42px 0 21px;
      font-size: 21px;
    }
    @include media('>=laptop') {
      margin: 48px 0 24px;
      font-size: 24px;
    }
  }

  // Heading 5
  @if $level == 'h5' {
    display: block;
    margin: 32px 0 16px;
    font: 700 16px / 1.25 $font-secondary;

    @include media('>=tablet') {
      margin: 28px 0 19px;
      font-size: 19px;
    }
    @include media('>=laptop') {
      margin: 42px 0 21px;
      font-size: 21px;
    }
  }

  // Heading 6
  @if $level == 'h6' {
    display: block;
    margin: 28px 0 14px;
    font: 600 14px / 1.25 $font-secondary;

    @include media('>=tablet') {
      margin: 32px 0 16px;
      font-size: 16px;
    }
    @include media('>=laptop') {
      margin: 28px 0 19px;
      font-size: 19px;
    }
  }

}
