// Typefaces
//
// Collection of all approved typefaces to comply with branding.
//
// <ul class="styleguide typefaces">
//   <li class="typeface">
//     <h5>FF Tibere</h5>
//     <p style="font-family: 'FF Tibere';">
//     <span>Aa</span><br>
//     ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
//     abcdefghijklmnopqrstuvwxyz<br>
//     0123456789</p>
//   </li>
//   <li class="typeface">
//     <h5>Open Sans</h5>
//     <p style="font-family: 'Open Sans';">
//     <span>Aa</span><br>
//     ABCDEFGHIJKLMNOPQRSTUVWXYZ<br>
//     abcdefghijklmnopqrstuvwxyz<br>
//     0123456789</p>
//   </li>
// </ul>
//
// Styleguide Branding - Typefaces

/**
 * @license
 * MyFonts Webfont Build ID 3425517, 2017-07-20T11:58:46-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Tibere Web Medium by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot-medium/
 *
 * Webfont: FF Tibere Web Light by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot-light/
 *
 * Webfont: FF Tibere Web Italic by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot-italic/
 *
 * Webfont: FF Tibere Web Light Italic by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot-light-italic/
 *
 * Webfont: FF Tibere Web Bold by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot-bold/
 *
 * Webfont: Tibere Ornaments Banded by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ornaments-banded/
 *
 * Webfont: FF Tibere Web by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/tibere/ot/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3425517
 * Licensed pageviews: 500,000
 * Webfonts copyright: 2010 Albert Boton published by FSI FontShop International GmbH
 *
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3444ed');

@include font-face('FF Tibere', '../fonts/3444ED_1_0', 300, $file-formats: eot woff2 woff ttf);
@include font-face('FF Tibere', '../fonts/3444ED_6_0', 500, $file-formats: eot woff2 woff ttf);
@include font-face('FF Tibere', '../fonts/3444ED_4_0', 700, $file-formats: eot woff2 woff ttf);


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');


// Font Families
// -----------------------------------------------------------------------------
$font-primary: 'FF Tibere', Palatino, Georgia, sans-serif;
$font-secondary: 'Open Sans', Helvetica, Arial, sans-serif;
// $base-font-family: 'Chronicle Text G2 A', 'Chronicle Text G2 B', $georgia;
// $brand-font-family-book: 'Avenir 45 Book', $helvetica;
// $brand-font-family-medium: 'Avenir 65 Medium', $helvetica;
// $brand-font-family-black: 'Avenir 95 Black', $helvetica;

// Font Sizes
// -----------------------------------------------------------------------------
// $base-font-size: rem(18);

// Line Heights
// -----------------------------------------------------------------------------
// $base-line-height: 1.5;
// $heading-line-height: 1.2;
