// Breadcrumbs
//
// Indicate the current page's location within a navigational hierarchy.
//
// Markup:
// <div class="breadcrumbs">
//   <div class="wrapper">
//     <ol class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
//       <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
//         <a itemscope itemtype="http://schema.org/Thing" itemprop="item" href="#">
//           <span itemprop="name">Home</span>
//         </a>
//         <meta itemprop="position" content="1"/>
//       </li>
//       <li class="parent" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
//         <a itemscope itemtype="http://schema.org/Thing" itemprop="item" href="#">
//           <span itemprop="name">Forms</span>
//         </a>
//         <meta itemprop="position" content="2"/>
//       </li>
//       <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
//         <span itemscope itemtype="http://schema.org/Thing" itemprop="item">
//           <span itemprop="name">Notice of petition to obtain title to abandoned property</span>
//         </span>
//         <meta itemprop="position" content="3"/>
//       </li>
//     </ol>
//   </div>
// </div>
//
// Styleguide Components - Breadcrumbs

$breadcrumb_spacing: 8px;
$breadcrumb_truncate: 200px;

.breadcrumbs {

  ol {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0;
    margin: 0;
    color: color(primary, gray);
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 1.25;
    list-style: none;
  }

  li {
    display: none;
    margin: 0 0 0 $breadcrumb_spacing;

    @include media('>=laptop') {
      display: inline-block;
    }

    // icon
    &:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 20px;
      margin-right: $breadcrumb_spacing;
      background: url('../img/icons/i_return.svg') no-repeat 50% 50% / 100% auto;
      vertical-align: middle;

      @include media('>=laptop') {
        display: none;
      }
    }

    // icon
    &:after {
      display: none;

      @include media('>=laptop') {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: $breadcrumb_spacing;
        background: url('../img/icons/i_caret-right.svg') no-repeat 50% 50% / 100% auto;
        vertical-align: middle;
      }
    }

    // first element
    &:first-child {
      margin-left: 0;
    }

    // last element
    &:last-child {
      &:after {
        display: none;
      }
    }

    // parent element
    &.parent {
      display: inline-block;
      margin-left: 0;

      @include media('>=laptop') {
        margin: 0 0 0 $breadcrumb_spacing;
      }

      &:first-child {
        @include media('>=laptop') {
          margin-left: 0;
        }
      }
    }
  }

  [itemprop='item'] {
    display: inline-block;
    max-width: $breadcrumb_truncate;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
