// Line Rule
//
// The hr element creates in the document a highly visible break that renders as a slim horizontal line running the width of the area to which it’s applied.
//
// Markup:
// <hr />
//
// Styleguide Elements - Line Rule

hr {
  height: 1px;
  margin: 32px 0;
  background-color: lighten(color(primary, gray), 35%);
  border: 0;
}
