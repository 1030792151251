// Input
//
// The HTML <code>input</code> element is used to create interactive controls for web-based forms in order to accept data from the user.
//
// Markup:
// <input type="text" id="inputID" name="inputID" placeholder="placeholder..." />
//
// Styleguide Elements - Form - Input

input {
  appearance: none;
  display: block;
  width: 100%;
  padding: 8px;
  border: 2px solid color(primary, gray);
  transition:
    border $base-animation-speed $base-animation-easing,
    box-shadow $base-animation-speed $base-animation-easing;

  // focus state
  &:focus {
    border-color: color(primary, blue);
    box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);
    outline: 0;
  }

  // hover state
  &:hover {
    border-color: color(primary, blue);
  }
}

// Disable built-in search input formats for supported browsers
input[type='search'] {
  appearance: none;
  border-radius: 0;
}

// Disable built-in spinner control for supported browsers
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}


// Textarea
//
// The HTML <code>textarea</code> element represents a multi-line plain-text editing control.
//
// Markup:
// <textarea id="inputID" name="inputID" placeholder="placeholder..."></textarea>
//
// Styleguide Elements - Form - Textarea

textarea {
  display: block;
  width: 100%;
  min-height: 128px;
  padding: 8px;
  border: 2px solid color(primary, gray);
  resize: vertical;
  transition:
    border $base-animation-speed $base-animation-easing,
    box-shadow $base-animation-speed $base-animation-easing;

  // focus state
  &:focus {
    border-color: color(primary, blue);
    box-shadow: 0 0 0 4px rgba(color(primary, blue), .16);
    outline: 0;
  }

  // hover state
  &:hover {
    border-color: color(primary, blue);
  }
}
